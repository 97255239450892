import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import companyLogo from "../../assets/images/logo.png";
import SunEditor from "suneditor-react";
import uploadRetreat from "../../assets/images/uploadRetreat.png";
import AuthApi from "../../api/authApi";
import RetreatApi from "../../api/retreatApi";
import { useTranslation } from "react-i18next";
import AlertDelete from "../../components/AlertDelete";
import moment from "moment-timezone";
import { useNavigate, Link } from "react-router-dom";
import DeleteModel from "../../components/DeleteModel";
import ReCAPTCHA from "react-google-recaptcha";
import TimePicker from "../../components/TimePicker";
const site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const CreateOuterRetreat = () => {
  let authApi = new AuthApi();
  let retreatApi = new RetreatApi();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [missionStatement, setMissionStatement] = useState("");
  const [description, setDescription] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [isTempUpload1, setIsTempUpload1] = useState(false);
  const [descriptionSample, setDescriptionSample] = useState(false);
  const [mailSample, setMailSample] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [Pass, setPass] = useState(false);
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [check, setCheck] = useState("");
  const [data, setData] = useState({
    parish: "",
    language: "",
    location: "",
    schedule: [],
    image1: "",
    heading: "",
    mission_statement: `"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."`,
    description: "",
    mail_msg: "",
    retreat_team: [
      {
        role: "lead_couple",
        hisFirstName: "",
        hisLastName: "",
        hisEmail: "",
        herFirstName: "",
        herLastName: "",
        herEmail: "",
      },
      {
        role: "co_lead_couple",
        hisFirstName: "",
        hisLastName: "",
        hisEmail: "",
        herFirstName: "",
        herLastName: "",
        herEmail: "",
      },
      {
        role: "mentor_couple",
        hisFirstName: "",
        hisLastName: "",
        hisEmail: "",
        herFirstName: "",
        herLastName: "",
        herEmail: "",
      },
    ],
    contact_name: "",
    contact_email: "",
    contact_mobile: "",
  });

  const recaptchaRef = useRef();

  const formattedDate = (date) => {
    return moment(date).tz("America/New_York").format("YYYY-MM-DD");
  };

  useEffect(() => {
    getMissionStatement();
  }, []);

  const getMissionStatement = async () => {
    await authApi
      .getMissionStatement()
      .then((response) => {
        if (response && response.data && response.data.mission_statement) {
          setMissionStatement(response.data.mission_statement);
        } else {
          setMissionStatement(
            `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`
          );
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const handleChanges = (e) => {
    let { name, value } = e.target;
    let d = { ...data };
    if (e.target.name === "language") {
      let missionData = "";
      if (e.target.value === "spanish") {
        d.mission_statement = `"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."`;
        setMissionStatement(
          `<p><em>"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."</em></p>`
        );
        missionData = `<p><em>"La misión de la Iglesia de Santa Monica y la del Ministerio de Matrimonios LOVESTRONG es el de apoyar, fortalecer y alimentar la unión centrada en Cristo, entre parejas casadas de nuestra Iglesia y Comunidad."</em></p>`;
      } else {
        d.mission_statement = `"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."`;
        setMissionStatement(
          `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`
        );
        missionData = `<p><em>"The mission of the LOVESTRONG Marriage Ministry is to support, strengthen, and nurture the Christ-centered marital bond between a man and a woman utilizing an experiential-based weekend retreat program."</em></p>`;
      }
      i18n.changeLanguage(e.target.value);
    }
    if (name == "image1") {
      d.image1 = e.target.files[0];
      toast.success("The retreat image has been successfully added.");
    } else {
      d[name] = value;
    }
    setData(d);
  };

  const upload = () => {
    document.getElementById("retreatImageUpload").click();
    setIsTempUpload1(true);
  };

  const remove = () => {
    let d = { ...data };
    d.image1 = "";
    setData(d);
    setIsTempUpload1(false);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      setIsTempUpload1(false);
      let data1 = new FormData();
      data1.append("file1", data.image1);
      let stateRef = { ...data };
      stateRef.mission_statement = missionStatement;
      stateRef.description = description;
      stateRef.mail_msg = emailMsg;
      delete stateRef.image1;
      if (stateRef.schedule && data.schedule.length) {
        if (check === "") {
          const recaptchaValue = recaptchaRef.current.getValue();
          // Verify reCAPTCHA here
          if (recaptchaValue) {
          stateRef.token = recaptchaValue;
          console.log("Recaptcha value:", recaptchaValue);
          data1.append("fromData", JSON.stringify(stateRef));
          // Proceed with form submission
          authApi
            .createTempRetreat(data1)
            .then((res) => {
              if (res.success) {
                toast.success(res.msg);
                navigate("/thankyou", {
                  state: "New Register Retreat",
                });
              } else {
                toast.error(res.msg);
              }
            })
            .catch((error) => {
              toast.error(error);
            });
          } else {
            toast.error("Please Submit Captcha");
          }
        }
      } else {
        toast.error("Atleast one time schedule is required!");
      }
    }
    setValidated(true);
  };

  const handleCloseAlert = () => {
    setDescriptionSample(false);
    setMailSample(false);
  };

  const handleTimeChange = (name, value, index) => {
    const newData = { ...data };

    // Ensure the schedule array has an entry at the specified index
    if (!newData.schedule[index]) {
      newData.schedule[index] = {};
    }

    if (name.includes("date")) {
      // Adjust the date for the specified timezone
      const adjustedDate = moment(value)
        .tz("America/New_York", true)
        .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

      // Set the date property for the schedule entry at the given index
      newData.schedule[index].date = adjustedDate;

      // Sort the schedule by date
      // newData.schedule.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Update day name based on the adjusted date and language
      newData.schedule.forEach((item) => {
        item.dayName = getDayName(item.date, newData.language);
      });
    } else if (name.includes("from")) {
      // Check if "From" and "To" times are the same
      if (newData.schedule[index].to && newData.schedule[index].to === value) {
        setCheck(`${index}`);
      } else {
        setCheck("");
        newData.schedule[index].from = value;
      }
    } else if (name.includes("to")) {
      // Check if "To" and "From" times are the same
      if (
        newData.schedule[index].from &&
        newData.schedule[index].from === value
      ) {
        setCheck(`${index}`);
      } else {
        setCheck("");
        newData.schedule[index].to = value;
      }
    }

    setData(newData);
  };

  const handleRetreatTeam = (e, couple) => {
    const { name, value } = e.target;
    setData((prevData) => {
      // Find the index of the couple role in the retreat_team array
      const updatedTeam = prevData.retreat_team.map((member) =>
        member.role === couple ? { ...member, [name]: value } : member
      );
      return {
        ...prevData,
        retreat_team: updatedTeam,
      };
    });
  };

  function getDayName(dateString, language) {
    const dateInUSTimezone = moment(dateString).tz("America/New_York");
    let days = [];
    if (language === "spanish") {
      days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
    } else {
      days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    }
    return days[dateInUSTimezone.day()];
  }

  const handleShow = (i) => {
    setEditIndex(i);
    setShow(true);
  };

  const deleteSchedule = () => {
    let d = { ...data };
    if (d.schedule.length > 0) {
      d.schedule.splice(editIndex, 1);
    }
    setShow(false);
  };

  const handleClose = () => {
    setEditIndex(null);
    setShow(false);
  };

  return (
    <div className="container-scroller mainpannel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-10 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <Link to="/dashboard">
                  {" "}
                  <div className="brand-logo">
                    <img src={companyLogo} alt="logo" className="logo center" />
                  </div>
                </Link>

                <div className="text-center mb-4">
                  <h3>Register New Retreat</h3>
                </div>
                <Form
                  onSubmit={handleSubmit}
                  noValidate
                  validated={validated}
                  className="forms-sample"
                >
                  <Row>
                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter the Parish Name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="parish"
                          id="parish"
                          placeholder="Parish"
                          value={data.parish}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Parish is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Select Retreat Language{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          required
                          size="sm"
                          type="text"
                          name="language"
                          id="language"
                          placeholder="Language"
                          value={data.language}
                          onChange={(e) => handleChanges(e)}
                        >
                          <option value="">Select</option>
                          <option value="english" className="dark-option">
                            English
                          </option>
                          <option value="spanish" className="dark-option">
                            Spanish
                          </option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Language is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter Location <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="location"
                          id="location"
                          placeholder="Location"
                          value={data.location}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Location is required!
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Retreat Theme</Form.Label>
                        <Form.Control
                          // required
                          size="sm"
                          type="text"
                          name="heading"
                          id="heading"
                          placeholder="Retreat Theme"
                          value={data.heading}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Retreat Theme is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                      <Form.Group>
                        {data.image1 == "" || data.image1 == undefined ? (
                          <div className="edit-profile-img mb-2 text-center">
                            <img src={uploadRetreat} />
                          </div>
                        ) : (
                          <div className="edit-profile-img mb-2 text-center">
                            {isTempUpload1 ? (
                              <>
                                <img src={URL.createObjectURL(data?.image1)} />
                              </>
                            ) : (
                              <>
                                <img src={data?.image1} />
                              </>
                            )}
                          </div>
                        )}{" "}
                        <div className="text-center mt-3">
                          {data.image1 == "" || data.image1 == undefined ? (
                            <>
                              <input
                                name="image1"
                                className="pt-1"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                id="retreatImageUpload"
                                onChange={(e) => handleChanges(e)}
                              />
                              <Button
                                variant="primary"
                                className="mb-1 text-center"
                                onClick={(e) => {
                                  upload();
                                }}
                              >
                                Upload Retreat Image
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="danger"
                              className="mb-1 mt-2 "
                              onClick={(e) => {
                                remove();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    <hr />

                    <Col lg={12} md={12} xs={12} className="mt-3">
                      <Row>
                        <Col lg={2} md={2} xs={2} className="mt-4">
                          <h4>Friday</h4>
                        </Col>

                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <div>
                            <div>
                              {" "}
                              <Form.Label>
                                Date
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                            <DatePicker
                              autoComplete="off"
                              id="dateId"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="mm-dd-yyyy"
                              className="form-select"
                              selected={
                                data &&
                                data.schedule[0] &&
                                data.schedule[0].date &&
                                data.schedule[0].date !== ""
                                  ? moment(
                                      formattedDate(data.schedule[0].date)
                                    ).toDate()
                                  : null
                              }
                              name="date0"
                              onChange={(date) =>
                                handleTimeChange("date0", date, 0)
                              }
                              filterDate={(date) => date.getDay() === 5} // 5 represents Friday
                            />

                            <Form.Control.Feedback type="invalid">
                              Atleast one date is required!
                            </Form.Control.Feedback>
                          </div>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>From</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("from0", time, 0)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data &&
                                data.schedule[0] &&
                                data.schedule[0].from
                              }
                              className="timePicker"
                            />
                            <Form.Control.Feedback type="invalid">
                              Time From is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>To</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("to0", time, 0)
                              }
                              value={
                                data && data.schedule[0] && data.schedule[0].to
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              className="timePicker indexVlues"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                              Time To is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mb-3 text-center">
                      {" "}
                      {check && check === "0" ? (
                        <h5 className="text-danger mt-2">
                          Time From' and 'Time To' should not be the same.
                        </h5>
                      ) : null}
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mt-3">
                      <Row>
                        <Col lg={2} md={2} xs={2} className="mt-4">
                          <h4>Saturday</h4>
                        </Col>

                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <div>
                            <div>
                              {" "}
                              <Form.Label>
                                Date
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                            <DatePicker
                              autoComplete="off"
                              id="dateId"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="mm-dd-yyyy"
                              className="form-select"
                              selected={
                                data &&
                                data.schedule[1] &&
                                data.schedule[1].date &&
                                data.schedule[1].date !== ""
                                  ? moment(
                                      formattedDate(data.schedule[1].date)
                                    ).toDate()
                                  : null
                              }
                              name="date1"
                              onChange={(date) =>
                                handleTimeChange("date1", date, 1)
                              }
                              filterDate={(date) => date.getDay() === 6}
                            />
                            <Form.Control.Feedback type="invalid">
                              Atleast one date is required!
                            </Form.Control.Feedback>
                          </div>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>From</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("from1", time, 1)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data &&
                                data.schedule[1] &&
                                data.schedule[1].from
                              }
                              className="timePicker"
                            />
                            <Form.Control.Feedback type="invalid">
                              Time From is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>To</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("to1", time, 1)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data && data.schedule[1] && data.schedule[1].to
                              }
                              className="timePicker indexVlues"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                              Time To is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mb-3 text-center">
                      {" "}
                      {check && check === "1" ? (
                        <h5 className="text-danger mt-2">
                          Time From' and 'Time To' should not be the same.
                        </h5>
                      ) : null}
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mt-3">
                      <Row>
                        <Col lg={2} md={2} xs={2} className="mt-4">
                          <h4>Sunday</h4>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                          {" "}
                          <div>
                            <div>
                              {" "}
                              <Form.Label>
                                Date
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                            <DatePicker
                              autoComplete="off"
                              id="dateId"
                              dateFormat="MM-dd-yyyy"
                              placeholderText="mm-dd-yyyy"
                              className="form-select"
                              selected={
                                data &&
                                data.schedule[2] &&
                                data.schedule[2].date &&
                                data.schedule[2].date !== ""
                                  ? moment(
                                      formattedDate(data.schedule[2].date)
                                    ).toDate()
                                  : null
                              }
                              name="date2"
                              onChange={(date) =>
                                handleTimeChange("date2", date, 2)
                              }
                              filterDate={(date) => date.getDay() === 0}
                            />
                            <Form.Control.Feedback type="invalid">
                              Atleast one date is required!
                            </Form.Control.Feedback>
                          </div>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>From</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("from2", time, 2)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data &&
                                data.schedule[2] &&
                                data.schedule[2].from
                              }
                              className="timePicker"
                            />
                            <Form.Control.Feedback type="invalid">
                              Time From is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={5} className="timeData">
                          {" "}
                          <Form.Group className="form-group">
                            <Form.Label>To</Form.Label>
                            <TimePicker
                              chekPicker={Pass}
                              useTwelveHourFormat={true}
                              showClockIcon={false}
                              showCloseIcon={true}
                              allowBackdrop={true}
                              onTimeChange={(time) =>
                                handleTimeChange("to2", time, 2)
                              }
                              placeholder="00 : 00 am"
                              defaulValue="00 : 00"
                              value={
                                data && data.schedule[2] && data.schedule[2].to
                              }
                              className="timePicker indexVlues"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                              Time To is required!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="mb-3 text-center">
                      {" "}
                      {check && check === "2" ? (
                        <h5 className="text-danger mt-2">
                          Time From' and 'Time To' should not be the same.
                        </h5>
                      ) : null}
                    </Col>

                    {/* <Col lg={12} md={12} xs={12}>
                      <Form.Group
                        className="form-group col-12 mt-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Mission Statement</Form.Label>
                        <SunEditor
                          id="outlined-adornment-quote"
                          // autoFocus={false}
                          type="text"
                          onChange={(e) => setMissionStatement(e)}
                          setContents={missionStatement}
                          name="mission_statement"
                          inputProps={{}}
                          setOptions={{
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                              ],
                              ["bold", "underline", "italic", "removeFormat"],
                              [
                                "fontColor",
                                "hiliteColor",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                              ],
                              ["fullScreen", "link", "save"],
                            ],
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Mission Statement is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col> */}

                    <Col lg={12} md={12} xs={12}>
                      {" "}
                      <Form.Group
                        className="form-group col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Description</Form.Label>
                        <h5
                          onClick={() => setDescriptionSample(true)}
                          className="btn sampleData"
                        >
                          Description Sample
                        </h5>
                        <SunEditor
                          id="outlined-adornment-quote"
                          type="text"
                          onChange={(e) => setDescription(e)}
                          setContents={description}
                          name="description"
                          inputProps={{}}
                          setOptions={{
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                              ],
                              ["bold", "underline", "italic", "removeFormat"],
                              [
                                "fontColor",
                                "hiliteColor",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                              ],
                              ["fullScreen", "link", "save"],
                            ],
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Description is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={12} md={12} xs={12} className="mb-3">
                      <Form.Group
                        className="form-group col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Email Message</Form.Label>
                        <h5
                          onClick={() => setMailSample(true)}
                          className="btn sampleData"
                        >
                          Mail Message Sample
                        </h5>
                        <SunEditor
                          id="outlined-adornment-quote"
                          type="text"
                          onChange={(e) => setEmailMsg(e)}
                          setContents={emailMsg}
                          name="mail_msg"
                          inputProps={{}}
                          setOptions={{
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                              ],
                              ["bold", "underline", "italic", "removeFormat"],
                              [
                                "fontColor",
                                "hiliteColor",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                              ],
                              ["fullScreen", "link", "save"],
                            ],
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Email message is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={12} md={12} xs={12} className="mb-3">
                      <h3> Retreat Team</h3>
                    </Col>

                    <Col lg={4} md={4} xs={12} className="border-right">
                      <h4>Lead Couple</h4>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="His First Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "lead_couple"
                              )?.hisFirstName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                            // readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            His FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="His Last Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "lead_couple"
                              )?.hisLastName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Last Name is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "lead_couple"
                              )?.hisEmail || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            placeholder="Her First Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "lead_couple"
                              )?.herFirstName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Her Last Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "lead_couple"
                              )?.herLastName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "lead_couple"
                              )?.herEmail || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Col>

                    <Col lg={4} md={4} xs={12} className="border-right">
                      <h4>Co-Lead Couple</h4>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="His First Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "co_lead_couple"
                              )?.hisFirstName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                            // readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            His FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="His Last Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "co_lead_couple"
                              )?.hisLastName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Last Name is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "co_lead_couple"
                              )?.hisEmail || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            placeholder="Her First Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "co_lead_couple"
                              )?.herFirstName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Her Last Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "co_lead_couple"
                              )?.herLastName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "co_lead_couple"
                              )?.herEmail || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "co_lead_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                      <h4>Mentor Couple</h4>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisFirstName"
                            id="hisFirstName"
                            placeholder="His First Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "mentor_couple"
                              )?.hisFirstName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                            // readOnly={isEditAdmin}
                          />
                          <Form.Control.Feedback type="invalid">
                            His FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="hisLastName"
                            id="hisLastName"
                            placeholder="His Last Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "mentor_couple"
                              )?.hisLastName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Last Name is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>His Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="hisEmail"
                            id="hisEmail"
                            placeholder="Email"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "mentor_couple"
                              )?.hisEmail || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            His Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her First Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herFirstName"
                            id="herFirstName"
                            placeholder="Her First Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "mentor_couple"
                              )?.herFirstName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her FirstName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Last Name</Form.Label>
                          <Form.Control
                            // required
                            size="sm"
                            type="text"
                            name="herLastName"
                            id="herLastName"
                            placeholder="Her Last Name"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "mentor_couple"
                              )?.herLastName || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her LastName is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group">
                          <Form.Label>Her Email</Form.Label>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="herEmail"
                            id="herEmail"
                            placeholder="Email"
                            value={
                              data.retreat_team.find(
                                (member) => member.role === "mentor_couple"
                              )?.herEmail || ""
                            }
                            onChange={(e) =>
                              handleRetreatTeam(e, "mentor_couple")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Her Email is required!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Col>
                  </Row>
                  <hr></hr>

                  <Row>
                    <h3 className="mt-3 mb-3">Contact Information</h3>

                    <Col lg={4} md={4} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="text"
                          name="contact_name"
                          id="contact_name"
                          placeholder="Contact Name"
                          value={data.contact_name}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Contact Name is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Enter Email <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="contact_email"
                          name="contact_email"
                          id="contact_email"
                          placeholder="Contact Email"
                          value={data.contact_email} // corrected to data.email
                          onChange={(e) => handleChanges(e)} // handle the onChange event
                        />
                        <Form.Control.Feedback type="invalid">
                          Contact Email is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          Mobile Number <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          size="sm"
                          type="number"
                          name="contact_mobile"
                          id="contact_mobile"
                          placeholder="Contact Mobile"
                          value={data.contact_mobile}
                          onChange={(e) => handleChanges(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Contact Mobile is required!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  {console.log("site_key--->", site_key)}
                  <ReCAPTCHA
                    sitekey={site_key} // Replace with your site key
                    ref={recaptchaRef}
                  />
                  <Button className="mt-3" variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {descriptionSample ? (
        <AlertDelete
          show={descriptionSample}
          alertHeader="Description Sample"
          alerMessage={
            <div>
              <div style={{ textAlign: "center" }}>
                <p style={{ color: "rgb(53, 53, 53)", fontSize: "16px" }}>
                  The cost is $150 per couple. A $50 deposit is due with the
                  registration form. We are limited as to the number of couples
                  we can accommodate, and the availability is on a first come,
                  first-serve basis. The registration cost covers dinner on
                  Friday, three meals on Saturday, and breakfast and lunch on
                  Sunday. It will also cover all retreat materials. Childcare is
                  not provided.
                </p>
                <p>
                  <a
                    href="https://square.link/u/vHBA29pd"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Click Here for Payment"
                  >
                    Click Here for Payment
                  </a>
                </p>
                <p>
                  <strong>
                    <em>
                      All Retreat Couples must commit to attending the entire
                      3-day weekend’s activities.
                    </em>
                  </strong>
                </p>
                <p style={{ fontSize: "20px", color: "rgb(34, 34, 34)" }}>
                  Sts. Peter and Paul Church
                  <br />
                  386 N Castell Ave.,
                  <br />
                  New Braunfels, TX, 78130
                </p>
                <p>For questions, here is the contact info:</p>
                <p>
                  <a
                    href="mailto:solizspp.lovestrong@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1155CC" }}
                  >
                    solizspp.lovestrong@gmail.com
                  </a>
                </p>
                <p style={{ color: "#222222" }}>
                  Phil (210-779-3096)
                  <br />
                  Jen (210-288-1908)
                </p>
                <p>Thank you!</p>
              </div>
            </div>
          }
          handleClose={handleCloseAlert}
        />
      ) : null}

      {mailSample ? (
        <AlertDelete
          show={mailSample}
          alertHeader="Mail Message Sample"
          alerMessage={
            <div>
              <div style={{ textAlign: "center" }}>
                <p>
                  Thank you for registering for the Sts. Peter and Paul
                  LOVESTRONG Marriage retreat scheduled for October 18-20, 2024.
                  We are excited that you will join us!
                </p>
                <p>
                  <a
                    href="https://square.link/u/vHBA29pd"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Click Here for Payment"
                  >
                    Click Here for Payment
                  </a>
                </p>
                <p>If you have any questions, please reach out to us here...</p>
                <p>
                  <strong>Email: </strong>
                  <a
                    href="mailto:solizspp.lovestrong@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1155CC" }}
                  >
                    solizspp.lovestrong@gmail.com
                  </a>
                </p>
                <p style={{ color: "rgb(34, 34, 34)" }}>
                  <strong>Jen Soliz</strong>
                  <br />
                  210-288-1908
                </p>
                <p style={{ color: "#222222" }}>
                  <strong>Phil Soliz</strong>
                  <br />
                  210-779-3096
                </p>
                <p style={{ fontSize: "12px" }}>
                  Please expect to hear from us soon!
                </p>
                <p style={{ fontSize: "12px" }}>
                  <strong>LIVE! LOVE! STRONG!</strong>
                </p>
              </div>
              ​
            </div>
          }
          handleClose={handleCloseAlert}
        />
      ) : null}

      {show ? (
        <DeleteModel
          show={show}
          handleClose={handleClose}
          DeleteData={deleteSchedule}
        ></DeleteModel>
      ) : null}
    </div>
  );
};

export default CreateOuterRetreat;
