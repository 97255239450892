import React, { useState, useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import {
  Tab,
  Tabs,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Row,
  Col,
  Popover,
} from "react-bootstrap";
import Select from "react-select";
import RetreatRoastApi from "../../../api/retreatRoasterApi";
import AuthApi from "../../../api/authApi";
import RolesApi from "../../../api/RolesApi";
import ServiceApi from "../../../api/ServiceApi";
import TalksApi from "../../../api/TalksApi";
import { toast } from "react-toastify";
import CustomDataTable from "../../../components/CustomDataTable";
import Helpers from "../../../components/Helpers";
import DeleteModel from "../../../components/DeleteModel";
import AdminApi from "../../../api/admin";
import RetreatApi from "../../../api/retreatApi";
import { Cookies, useCookies } from "react-cookie";
import { utils, writeFile } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment-timezone";
import Container from "react-bootstrap/Container";
import DatePicker from "react-datepicker";
import Profile from "../../../assets/images/couple-default.png";
import UpdateCouplePopUp from "../../common/UpdateCouplePopUp";
const XLSX = require("xlsx");

const RetreatRoaster = () => {
  let cookies = new Cookies();
  let userData = cookies.get("LoveStrongUser") || null;
  let adminApi = new AdminApi();
  let retreatRoastApi = new RetreatRoastApi();
  let retreatApi = new RetreatApi();
  const [cookie, setCookie] = useCookies(["LoveStrongUser"]);
  const [retreatRoastData, setRetreatRoastData] = useState([]);
  const [retreatRoastLeadData, setRetreatRoastLeadData] = useState([]);
  const [total, setTotal] = useState(20);
  const [LoadData, setLoadData] = useState(false);
  const [totalRoast, setTotalRoast] = useState(10);
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchKey, setSearchKey] = useState(""); //for searching
  const [retreatDetail, setRetreatDetail] = useState("");
  let retreatId = searchParams.get("retreatId");
  let flag = searchParams.get("flag");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [service_count, Setservice_count] = useState("");
  const [talk_count, Settalk_count] = useState("");
  const [team_count, SetTeam_count] = useState("");
  const [ret_count, Setret_count] = useState("");
  const [roast_id, setRoast_id] = useState("");
  const [spritual_count, setSpritual_count] = useState(0);
  const [lead_count, setLead_count] = useState(0);
  const [showRegModal, setShowREgModal] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [ediUserIdClicked, setEdiUserIdClicked] = useState("");
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [allCoupleList, setAllCoupleList] = useState([]);
  const [roastersOfCoupleList, setRoastersOfCoupleList] = useState([]);
  const [isNewCouple, setIsNewCouple] = useState(false);
  const [roasterCount, setRoasterCount] = useState("");

  const [data, setData] = useState({
    id: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    parishId: "",
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
  });

  const [roasterdData, setRoasterdData] = useState({
    id: "",
    hisFirstName: "",
    hisLastName: "",
    hisEmail: "",
    hisMobile: "",
    herFirstName: "",
    herLastName: "",
    herEmail: "",
    herMobile: "",
    city: "",
    state: "",
    zip: "",
    notes: "",
    imageUrl: "",
    // marriageImageUrl: "",
    attendeeType: "",
    roleId: null,
    serviceId: null,
    talkId: null,
    retreatId: retreatId,
    address: "",
    allergies: "",
    anniversary_date: "",
    under_age_35: "NO",
    emergency_name1: "",
    emergency_relationship1: "",
    emergency_phone1: "",
    emergency_name2: "",
    emergency_relationship2: "",
    emergency_phone2: "",
    hear_us: [],
    referral_from_a_friend_or_family_member: "",
  });

  let authApi = new AuthApi();
  let rolesApi = new RolesApi();
  let serviceApi = new ServiceApi();
  let talksApi = new TalksApi();
  const [parishList, setParishList] = useState([]);
  const [rolesDataList, setrolesDataList] = useState([]);
  const [serviceDataList, setServiceDataList] = useState([]);
  const [talksDataList, setTalksDataList] = useState([]);
  const [updateRoasterData, setUpdateRoasterData] = useState([]);
  const [alreadyPk, setAlreadyPk] = useState();
  const [validated, setValidated] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [oldValues, setOldValues] = useState([]);

  const handleShow = (item) => {
    setRoast_id(item.id);
    setShow(true);
    let d = { ...roasterdData };
    d.id = item.id;
    d.primaryKey = item.primaryKey;
    d.hisFirstName = item.hisFirstName;
    d.hisLastName = item.hisLastName;
    d.hisEmail = item.hisEmail;
    d.herFirstName = item.herFirstName;
    d.herLastName = item.herLastName;
    d.herEmail = item.herEmail;
    setRoasterdData(d);
  };

  const handleAgeChanges = (e, check) => {
    const { name, checked } = e.target;
    if (check === "forRoaster") {
      let o = { ...roasterdData };
      o.under_age_35 = checked ? "YES" : "NO";
      setRoasterdData(o);
    } else {
      let o = { ...data };
      o.under_age_35 = checked ? "YES" : "NO";
      setData(o);
    }
  };

  const handleDatePicker = (e, check) => {
    if (check === "forRoaster") {
      let o = { ...roasterdData };
      o.anniversary_date = e;
      setRoasterdData(o);
    } else {
      let o = { ...data };
      o.anniversary_date = e;
      setData(o);
    }
  };

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   let hearUsArray = Array.isArray(data.hear_us)
  //     ? data.hear_us
  //     : JSON.parse(data.hear_us);

  //   let updatedOptions;
  //   if (checked) {
  //     updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
  //   } else {
  //     updatedOptions = hearUsArray.filter((option) => option !== name);
  //   }
  //   setData({ ...data, hear_us: updatedOptions });
  // };

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   let hearUsArray = Array.isArray(data.hear_us)
  //     ? data.hear_us
  //     : JSON.parse(data.hear_us);

  //   let updatedOptions;
  //   if (checked) {
  //     updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
  //   } else {
  //     updatedOptions = hearUsArray.filter((option) => option !== name);
  //   }
  //   setData({ ...data, hear_us: updatedOptions });
  // };

  const handleCheckboxChange = (e, check) => {
    const { name, checked } = e.target;
    if (check === "forRoaster") {
      let hearUsArray;
      if (roasterdData.hear_us) {
        if (Array.isArray(roasterdData.hear_us)) {
          hearUsArray = roasterdData.hear_us;
        } else {
          hearUsArray = JSON.parse(roasterdData.hear_us);
        }
      } else {
        hearUsArray = [];
      }
      let updatedOptions;
      if (checked) {
        updatedOptions = [...hearUsArray, name];
      } else {
        updatedOptions = hearUsArray.filter((option) => option !== name);
      }
      setRoasterdData({ ...roasterdData, hear_us: updatedOptions });
    } else {
      let hearUsArray = Array.isArray(data.hear_us)
        ? data.hear_us
        : JSON.parse(data.hear_us);

      let updatedOptions;
      if (checked) {
        updatedOptions = hearUsArray ? [...hearUsArray, name] : [name];
      } else {
        updatedOptions = hearUsArray.filter((option) => option !== name);
      }
      setData({ ...data, hear_us: updatedOptions });
    }
  };

  const getPerishList = async () => {
    await authApi
      .getPerishList()
      .then((response) => {
        const sortedList = response.data.sort((a, b) =>
          a.parish.localeCompare(b.parish)
        );
        setParishList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getrolesList = async () => {
    await rolesApi
      .getAllRoleList()
      .then(async (response) => {
        const sortedList = response.data.sort((a, b) =>
          a.role.localeCompare(b.role)
        );
        setrolesDataList(response.data);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getServiceList = async () => {
    await serviceApi
      .getAllServiceList()
      .then(async (response) => {
        const sortedList = response.data.sort((a, b) =>
          a.service.localeCompare(b.service)
        );
        setServiceDataList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const getTalksList = async () => {
    await talksApi
      .getAllTalkList()
      .then(async (response) => {
        const sortedList = response.data.sort((a, b) =>
          a.talk.localeCompare(b.talk)
        );
        setTalksDataList(sortedList);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const clearfield = () => {
    setData({
      id: "",
      hisFirstName: "",
      hisLastName: "",
      hisEmail: "",
      hisMobile: "",
      herFirstName: "",
      herLastName: "",
      herEmail: "",
      herMobile: "",
      city: "",
      state: "",
      zip: "",
      notes: "",
      imageUrl: "",
      // marriageImageUrl: "",
      parishId: null,
      address: "",
      allergies: "",
      anniversary_date: "",
      under_age_35: false,
      emergency_name1: "",
      emergency_relationship1: "",
      emergency_phone1: "",
      emergency_name2: "",
      emergency_relationship2: "",
      emergency_phone2: "",
      hear_us: [],
      referral_from_a_friend_or_family_member: "",
      userRole: "COUPLE",
    });

    setOldValues(null);
    setValidated(false);
  };

  const CloseCompose = () => {
    setIsEditAdmin(false);
    setValidated(false);
    setShowREgModal(false);
    setEdiUserIdClicked("");
    clearfield();
  };

  useEffect(() => {
    getCouplesInRetreat();
    getRetreatRoastData();
    getRetreatRoastData1();
    getRetreatDetail();
    leadCoupleData1();
    checkPKCouple();
    getPerishList();
    if (flag == "1") {
      RetreatData();
    } else if (flag == "2") {
      teamData();
    }
  }, []);

  useEffect(() => {
    if (retreatDetail && retreatDetail.title) {
      let report = {
        byWhom:
          `${userData && userData.userName ? userData.userName : null} ` +
          ` (${userData && userData.userRole ? userData.userRole : null})`,
        pagelink: window.location.href,
        description: `Visited on Retreat Roaster page (Retreat : ${
          retreatDetail.title.charAt(0).toUpperCase() +
          retreatDetail.title.slice(1)
        }).`,
        action: "View",
      };
      adminApi
        .addLogReport(report)
        .then((response) => {
          if (response.success) {
            console.log("Created new log report.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [retreatDetail.title]);

  const [isTempUpload, setIsTempUpload] = useState(false);
  // const [isTempUpload2, setIsTempUpload2] = useState(false);

  const getCouplesInRetreat = async () => {
    setLoadData(true);
    await retreatRoastApi
      .getCouplesInRetreat({ retreatId: retreatId })
      .then((res) => {
        if (res.success) {
          let retreatCouple = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.primaryKey) {
                retreatCouple.push({
                  label:
                    `(${
                      item && item.primaryKey ? `${item.primaryKey} - ` : ""
                    }` +
                    `${item && item.hisLastName ? `${item.hisLastName}` : ""}` +
                    `${
                      item && item.hisFirstName ? `, ${item.hisFirstName}` : ""
                    }` +
                    `${
                      item && item.herFirstName
                        ? ` and  ${item.herFirstName}`
                        : ""
                    })`,
                  value: item.id,
                });
              }
            });
          setAllCoupleList(retreatCouple);
          setLoadData(false);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const checkPKCouple = async () => {
    await authApi
      .checkPKCouple()
      .then((res) => {
        if (res.success) {
          if (res.data != null) {
            let incPK = res.data + 1;
            setAlreadyPk(incPK);
            let d = { ...data };
            let d1 = { ...roasterdData };
            d.primaryKey = incPK;
            d1.primaryKey = incPK;
            setRoasterdData(d1);
            setData(d);
          } else {
            let defaultPK = 1001;
            setAlreadyPk(defaultPK);
            let d = { ...data };
            let d1 = { ...roasterdData };
            d1.primaryKey = defaultPK;
            d.primaryKey = defaultPK;
            setRoasterdData(d1);
            setData(d);
          }
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
        // console.log('error: ', error);
      });
  };

  const getroastersOfCouple = async (id) => {
    await adminApi
      .participatedInRetreats({ couple_Id: id })
      .then(async (response) => {
        setRoastersOfCoupleList(response.data);
        setRoasterCount(response.totalRecords);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const upload = () => {
    document.getElementById("CoupleProfileUpload").click();
    setIsTempUpload(true);
  };

  // const uploadMarriagePhoto = () => {
  //   document.getElementById("MarriagePhotoUpload").click();
  //   setIsTempUpload2(true);
  // };

  const remove1 = () => {
    let d = { ...roasterdData };
    d.imageUrl = "";
    setRoasterdData(d);
    setIsTempUpload(false);
  };

  // const removeMarriagePhoto1 = () => {
  //   let d = { ...roasterdData };
  //   d.marriageImageUrl = "";
  //   setRoasterdData(d);
  //   // setIsTempUpload2(false);
  // };

  const editCouple = async (id) => {
    // setIsDisabled(true)
    setEdiUserIdClicked(id);
    setIsTempUpload(false);
    let d = { ...data };
    await adminApi
      .getCouplesDetail({ coupleId: id })
      .then(async (res) => {
        if (
          res.data[0].hisEmail === "tkolkebeck@me.com" ||
          res.data[0].herEmail === "tkolkebeck@satx.rr.com" ||
          res.data[0].hisEmail === "jimmieflores@gmail.com" ||
          res.data[0].herEmail === "dulceaflores@gmail.com"
        ) {
          setIsEditAdmin(true);
        }
        if (res.success) {
          d.id = res.data[0].id;
          d.primaryKey = res.data[0].primaryKey;
          d.hisFirstName = res.data[0].hisFirstName;
          d.hisLastName = res.data[0].hisLastName;
          d.hisEmail = res.data[0].hisEmail;
          d.hisMobile = res.data[0].hisMobile;
          d.herFirstName = res.data[0].herFirstName;
          d.herLastName = res.data[0].herLastName;
          d.herEmail = res.data[0].herEmail;
          d.herMobile = res.data[0].herMobile;
          d.city = res.data[0].city;
          d.state = res.data[0].state;
          d.zip = res.data[0].zip;
          d.notes = res.data[0].notes;
          d.parishId = res.data[0].parishId;
          d.imageUrl = res.data[0].imageUrl;
          // d.marriageImageUrl = res.data[0].marriageImageUrl;
          d.address = res.data[0].address;
          d.allergies = res.data[0].allergies;
          d.anniversary_date = res.data[0].anniversary_date
            ? new Date(res.data[0].anniversary_date)
            : "";
          d.under_age_35 = res.data[0].under_age_35;
          d.emergency_name1 = res.data[0].emergency_name1;
          d.emergency_relationship1 = res.data[0].emergency_relationship1;
          d.emergency_phone1 = res.data[0].emergency_phone1;
          d.emergency_name2 = res.data[0].emergency_name2;
          d.emergency_relationship2 = res.data[0].emergency_relationship2;
          d.emergency_phone2 = res.data[0].emergency_phone2;
          d.hear_us = res.data[0].hear_us;
          d.referral_from_a_friend_or_family_member =
            res.data[0].referral_from_a_friend_or_family_member;
          setData(d);
          setOldValues(res.data[0]);
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Checked Detail of Couple (${d && d.primaryKey} - ${
              res && res.data && res.data[0] && res.data[0].herFirstName
            } ${res && res.data && res.data[0] && res.data[0].herLastName})`,
            action: "View",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("New log report created.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getRetreatRoastData = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = false;
    d.retreatId = retreatId;
    setLoadData(true);
    await retreatRoastApi
      .getRetreatRoastData(d)
      .then((res) => {
        if (res.success) {
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          setRetreatRoastData(res.data);
          Setservice_count(res.servTalkTeamTotal.serviceTotal);
          Settalk_count(res.servTalkTeamTotal.talksTotal);
          SetTeam_count(res.servTalkTeamTotal.countTeam);
          Setret_count(res.servTalkTeamTotal.countret);
          setTotal(totalRecords);
          setTotalRoast(totalRecords);
          setLoadData(false);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const clearFilter = async () => {
    getRetreatRoastData();
  };

  const allParticipants = async () => {
    getRetreatRoastData();
  };

  const spritualData = async () => {
    let d = {};
    d.retreatId = retreatId;
    await retreatRoastApi
      .getSpritualRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const leadCoupleData1 = async () => {
    let d = {};
    d.retreatId = retreatId;
    setLoadData(true);
    await retreatRoastApi
      .getLeadCoupleRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastLeadData(res.data);
          setLoadData(false);
        } else {
          toast.error(res.msg);
          setLoadData(false);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const leadCoupleData = async () => {
    let d = {};
    d.retreatId = retreatId;
    await retreatRoastApi
      .getLeadCoupleRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const teamData = async () => {
    let d = {};
    d.retreatId = retreatId;
    await retreatRoastApi
      .getTeamRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const setStates = async (row) => {
    console.log("row--->", row);
    setEdiUserIdClicked(row.id);
    let d = { ...roasterdData };
    let r = { ...oldData };
    d.id = row && row.id;
    d.primaryKey = row && row.primaryKey;
    d.hisLastName = row && row.hisLastName;
    d.hisFirstName = row && row.hisFirstName;
    d.herFirstName = row && row.herFirstName;
    d.hisEmail = row && row.hisEmail;
    d.hisMobile = row && row.hisMobile;
    d.herLastName = row && row.herLastName;
    d.herEmail = row && row.herEmail;
    d.herMobile = row && row.herMsetOldValuesobile;
    d.city = row && row.city;
    d.state = row && row.state;
    d.zip = row && row.zip;
    d.notes = row && row.notes;
    d.imageUrl = row && row.imageUrl;
    // d.marriageImageUrl = row && row.marriageImageUrl;
    d.attendeeType = row && row.attendeeType;
    d.roleId = row && row.roleId;
    d.serviceId = row && row.serviceId;
    d.talkId = row && row.talkId;
    d.coupleId = row && row.coupleId;
    d.address = row && row.address;
    d.allergies = row && row.allergies;
    d.anniversary_date =
      row && row.anniversary_date ? new Date(row && row.anniversary_date) : "";
    d.under_age_35 = row && row.under_age_35;
    d.emergency_name1 = row && row.emergency_name1;
    d.emergency_relationship1 = row && row.emergency_relationship1;
    d.emergency_phone1 = row && row.emergency_phone1;
    d.emergency_name2 = row && row.emergency_name2;
    d.emergency_relationship2 = row && row.emergency_relationship2;
    d.emergency_phone2 = row && row.emergency_phone2;
    d.hear_us = row && row.hear_us;
    d.referral_from_a_friend_or_family_member =
      row && row.referral_from_a_friend_or_family_member;

    setRoasterdData(d);
    setOldValues(row);
    getPerishList();
    getrolesList();
    getServiceList();
    getTalksList();
  };

  const handleChangeRoaster = (e) => {
    let { name, value } = e.target;
    let d = { ...roasterdData };
    let o = { ...oldData };
    if (name === "hisFirstName") {
      o.hisFirstName = oldValues && oldValues.hisFirstName;
    } else if (name === "hisLastName") {
      o.hisLastName = oldValues && oldValues.hisLastName;
    } else if (name === "hisEmail") {
      o.hisEmail = oldValues && oldValues.hisEmail;
    } else if (name === "hisMobile") {
      o.hisMobile = oldValues && oldValues.hisMobile;
    } else if (name === "herFirstName") {
      o.herFirstName = oldValues && oldValues.herFirstName;
    } else if (name === "herLastName") {
      o.herLastName = oldValues && oldValues.herLastName;
    } else if (name === "herEmail") {
      o.herEmail = oldValues && oldValues.herEmail;
    } else if (name === "herMobile") {
      o.herMobile = oldValues && oldValues.herMobile;
    } else if (name === "parishId") {
      o.parishId = oldValues && oldValues.parishId;
    } else if (name === "notes") {
      o.notes = oldValues && oldValues.notes;
    } else if (name === "zip") {
      o.zip = oldValues && oldValues.zip;
    } else if (name === "city") {
      o.city = oldValues && oldValues.city;
    } else if (name === "state") {
      o.state = oldValues && oldValues.state;
    } else if (name === "imageUrl") {
      o.imageUrl = oldValues && oldValues.imageUrl;
    }
    // else if (name === "marriageImageUrl") {
    //   o.marriageImageUrl = oldValues && oldValues.marriageImageUrl;
    // }
    else if (name === "attendeeType") {
      o.attendeeType = oldValues && oldValues.attendeeType;
    } else if (name === "roleId") {
      o.roleId = oldValues && oldValues.roleId;
    } else if (name === "serviceId") {
      o.serviceId = oldValues && oldValues.serviceId;
    } else if (name === "talkId") {
      o.talkId = oldValues && oldValues.talkId;
    } else if (name === "retreatId") {
      o.retreatId = oldValues && oldValues.retreatId;
    }
    setOldData(o);
    if (name == "imageUrl") {
      d.imageUrl = e.target.files[0];
      toast.success("The profile image has been successfully added.");
    }
    // else if (name == "marriageImageUrl") {
    //   d.marriageImageUrl = e.target.files[0];
    //   toast.success("The Wedding image has been successfully added.");
    // }
    else {
      d[name] = value;
    }
    setRoasterdData(d);
  };

  const handleRoasterData = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      if (
        ediUserIdClicked &&
        ediUserIdClicked !== "" &&
        ediUserIdClicked !== null
      ) {
        let data1 = new FormData();
        if (roasterdData.imageUrl !== "") {
          data1.append("file", roasterdData.imageUrl);
        }
        // if (roasterdData.marriageImageUrl !== "") {
        //   data1.append("marriage_file", roasterdData.marriageImageUrl);
        // }
        console.log("roasterdData00>", roasterdData);
        let stateRef = { ...roasterdData };
        // delete stateRef.imageUrl
        data1.append("fromData", JSON.stringify(stateRef));
        retreatRoastApi
          .updateRoaster(data1)
          .then(async (res) => {
            if (res.success) {
              if (res && res.data && res.data[0]) {
                userData.imageUpload =
                  res && res.data && res.data[0] && res.data[0].imageUrl
                    ? res.data[0].imageUrl
                    : "";
                console.log("2222222222222222222");
                //userData.marriageImageUpload = res.data[0].marriageImageUrl;
                if (userData.coupleId === res.data[0].coupleId) {
                  setCookie("LoveStrongUser", userData, {
                    path: "/",
                    maxAge: 60 * 60 * 100,
                    expires: new Date(Date.now() + 60 * 60 * 100),
                  });
                }
                console.log("333333333333");
                console.log("oldData--->", oldData);
                toast.success(res.msg);
                if (
                  oldData.hisFirstName ||
                  (oldData.hisFirstName === "" &&
                    oldData.hisFirstName !== res.data[0].hisFirstName)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HisFirstName of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.hisFirstName ? oldData.hisFirstName : "Nil"
                      }" to "${
                        res.data[0].hisFirstName
                          ? res.data[0].hisFirstName
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("55555555");
                if (
                  oldData.hisLastName ||
                  (oldData.hisLastName === "" &&
                    oldData.hisLastName !== res.data[0].hisLastName)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HisLastName of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.hisLastName ? oldData.hisLastName : "Nil"
                      }" to "${
                        res.data[0].hisLastName
                          ? res.data[0].hisLastName
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("666666666666");

                if (
                  oldData.hisEmail ||
                  (oldData.hisEmail === "" &&
                    oldData.hisEmail !== res.data[0].hisEmail)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HisEmail of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.hisEmail ? oldData.hisEmail : "Nil"
                      }" to "${
                        res.data[0].hisEmail ? res.data[0].hisEmail : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("777777777777");

                if (
                  oldData.hisMobile ||
                  (oldData.hisMobile === "" &&
                    oldData.hisMobile !== res.data[0].hisMobile)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HisMobile of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.hisMobile ? oldData.hisMobile : "Nil"
                      }" to "${
                        res.data[0].hisMobile ? res.data[0].hisMobile : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("8888888888");

                if (
                  oldData.herFirstName ||
                  (oldData.herFirstName === "" &&
                    oldData.herFirstName !== res.data[0].herFirstName)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HerFirstName of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.herFirstName ? oldData.herFirstName : "Nil"
                      }" to "${
                        res.data[0].herFirstName
                          ? res.data[0].herFirstName
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("9999999999999");

                if (
                  oldData.herLastName ||
                  (oldData.herLastName === "" &&
                    oldData.herLastName !== res.data[0].herLastName)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HerLastName of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.herLastName ? oldData.herLastName : "Nil"
                      }" to "${
                        res.data[0].herLastName
                          ? res.data[0].herLastName
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("1111-----0000");

                if (
                  oldData.herEmail ||
                  (oldData.herEmail === "" &&
                    oldData.herEmail !== res.data[0].herEmail)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HerEmail of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.herEmail ? oldData.herEmail : "Nil"
                      }" to "${
                        res.data[0].herEmail ? res.data[0].herEmail : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (
                  oldData.herMobile ||
                  (oldData.herMobile === "" &&
                    oldData.herMobile !== res.data[0].herMobile)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated HerMobile of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.herMobile ? oldData.herMobile : "Nil"
                      }" to "${
                        res.data[0].herMobile ? res.data[0].herMobile : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (
                  oldData.notes ||
                  (oldData.notes === "" && oldData.notes !== res.data[0].notes)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated Notes of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${oldData.notes ? oldData.notes : "Nil"}" to "${
                        res.data[0].notes ? res.data[0].notes : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (
                  oldData.zip ||
                  (oldData.zip === "" && oldData.zip !== res.data[0].zip)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated Zip of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${oldData.zip ? oldData.zip : "Nil"}" to "${
                        res.data.zip ? res.data.zip : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (
                  oldData.city ||
                  (oldData.city === "" && oldData.city !== res.data[0].city)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated City of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${oldData.city ? oldData.city : "Nil"}" to "${
                        res.data.city ? res.data.city : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (
                  oldData.state ||
                  (oldData.state === "" && oldData.state !== res.data[0].state)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated State of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${oldData.state ? oldData.state : "Nil"}" to "${
                        res.data.state ? res.data.state : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("333333333333");

                if (
                  oldData.imageUrl ||
                  (oldData.imageUrl === "" &&
                    oldData.imageUrl !== res.data[0].imageUrl)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated ImageUrl of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })`,

                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log("4444444");
                // if (
                //   oldData.marriageImageUrl ||
                //   (oldData.marriageImageUrl === "" &&
                //     oldData.marriageImageUrl !== res.data[0].marriageImageUrl)
                // ) {
                //   let report = {
                //     byWhom:
                //       `${
                //         userData && userData.userName ? userData.userName : null
                //       } ` +
                //       ` (${
                //         userData && userData.userRole ? userData.userRole : null
                //       })`,
                //     pagelink: window.location.href,
                //     description:
                //       `Updated MarriageImageUrl of Couple in Retreat Roast ` +
                //       `(Retreat : ${
                //         retreatDetail &&
                //         retreatDetail.title &&
                //         retreatDetail.title.charAt(0).toUpperCase() +
                //           retreatDetail.title.slice(1)
                //       }), ` +
                //       `Couple : (${
                //         roasterdData.primaryKey
                //           ? `${roasterdData.primaryKey} - `
                //           : ""
                //       }` +
                //       `${
                //         roasterdData && roasterdData.hisLastName
                //           ? `${roasterdData.hisLastName}`
                //           : ""
                //       }` +
                //       `${
                //         roasterdData && roasterdData.hisFirstName
                //           ? `, ${roasterdData.hisFirstName}`
                //           : ""
                //       }` +
                //       `${
                //         roasterdData && roasterdData.herFirstName
                //           ? ` and  ${roasterdData.herFirstName}`
                //           : ""
                //       })`,

                //     action: "Update",
                //   };
                //   await adminApi
                //     .addLogReport(report)
                //     .then((response) => {
                //       if (response.success) {
                //         console.log("Created new log report.");
                //       }
                //     })
                //     .catch((error) => {
                //       console.log(error);
                //     });
                // }

                let oldRole = rolesDataList.find(
                  (i) => i.id === oldData.roleId
                );
                let newRole = rolesDataList.find(
                  (i) => i.id === res.roastData.roleId
                );
                if (
                  oldData.roleId !== undefined &&
                  (oldData.roleId || oldData.roleId === null)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated Role of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldRole && oldRole.role && oldRole.role !== undefined
                          ? oldRole.role
                          : "Nil"
                      }" to "${
                        newRole && newRole.role && newRole.role !== undefined
                          ? newRole.role
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }

                let oldService = serviceDataList.find(
                  (i) => i.id === oldData.serviceId
                );
                let newService = serviceDataList.find(
                  (i) => i.id === res.roastData.serviceId
                );

                if (
                  oldData.serviceId !== undefined &&
                  (oldData.serviceId || oldData.serviceId === null)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated Service of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldService &&
                        oldService.service &&
                        oldService.service !== undefined
                          ? oldService.service
                          : "Nil"
                      }" to "${
                        newService &&
                        newService.service &&
                        newService.service !== undefined
                          ? newService.service
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                let oldTalk = talksDataList.find(
                  (i) => i.talkId == oldData.talkId
                );
                let newTalk = talksDataList.find(
                  (i) => i.talkId == res.roastData.talkId
                );
                if (
                  oldData.talkId !== undefined &&
                  (oldData.talkId || oldData.talkId === null)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated Talk of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldTalk && oldTalk.talk && oldTalk.talk !== undefined
                          ? oldTalk.talk
                          : "Nil"
                      }" to "${
                        newTalk && newTalk.talk && newTalk.talk !== undefined
                          ? newTalk.talk
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (
                  oldData.attendeeType ||
                  (oldData.attendeeType === "" &&
                    oldData.attendeeType !== res.roastData.attendeeType)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated AttendeeType of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldData.attendeeType ? oldData.attendeeType : "Nil"
                      }" to "${
                        res.roastData.attendeeType
                          ? res.roastData.attendeeType
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                let oldparish = parishList.find(
                  (i) => i.id === oldData.parishId
                );
                let newparish = parishList.find(
                  (i) => i.id === res.data[0].parishId
                );
                if (
                  oldData.parishId !== undefined &&
                  (oldData.parishId || oldData.parishId === null)
                ) {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,
                    description:
                      `Updated Parish of Couple in Retreat Roast ` +
                      `(Retreat : ${
                        retreatDetail &&
                        retreatDetail.title &&
                        retreatDetail.title.charAt(0).toUpperCase() +
                          retreatDetail.title.slice(1)
                      }), ` +
                      `Couple : (${
                        roasterdData.primaryKey
                          ? `${roasterdData.primaryKey} - `
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisLastName
                          ? `${roasterdData.hisLastName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.hisFirstName
                          ? `, ${roasterdData.hisFirstName}`
                          : ""
                      }` +
                      `${
                        roasterdData && roasterdData.herFirstName
                          ? ` and  ${roasterdData.herFirstName}`
                          : ""
                      })` +
                      ` From "${
                        oldparish &&
                        oldparish.parish &&
                        oldparish.parish !== undefined
                          ? oldparish.parish
                          : "Nil"
                      }" to "${
                        newparish &&
                        newparish.parish &&
                        newparish.parish !== undefined
                          ? newparish.parish
                          : "Nil"
                      }"`,
                    action: "Update",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                setOldData(null);
                clearfield();
                setRoasterdData({
                  id: "",
                  hisFirstName: "",
                  hisLastName: "",
                  hisEmail: "",
                  hisMobile: "",
                  herFirstName: "",
                  herLastName: "",
                  herEmail: "",
                  herMobile: "",
                  city: "",
                  state: "",
                  zip: "",
                  notes: "",
                  imageUrl: "",
                  // marriageImageUrl: "",
                  attendeeType: "",
                  roleId: null,
                  serviceId: null,
                  talkId: null,
                  retreatId: retreatId,
                });
                console.log("11111111111111111@@@@@@@@@");
                setShowModel(false);
                getRetreatRoastData();
                getRetreatRoastData1();
                leadCoupleData1();
                setIsTempUpload(false);
                // setIsTempUpload2(false);
                setIsNewCouple(false);
                getCouplesInRetreat();
              }
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        let data1 = new FormData();
        data1.append("file", roasterdData.imageUrl);
        // data1.append("marriage_file", roasterdData.marriageImageUrl);
        let stateRef = { ...roasterdData };
        delete stateRef.imageUrl;
        // delete stateRef.marriageImageUrl;
        data1.append("fromData", JSON.stringify(stateRef));
        retreatRoastApi
          .CreateRoaster(data1)
          .then(async (res) => {
            if (res.success) {
              let report = {
                byWhom:
                  `${
                    userData && userData.userName ? userData.userName : null
                  } ` +
                  ` (${
                    userData && userData.userRole ? userData.userRole : null
                  })`,
                pagelink: window.location.href,
                description:
                  `Added new couple in Retreat Roast` +
                  ` (Retreat : ${
                    retreatDetail &&
                    retreatDetail.title &&
                    retreatDetail.title.charAt(0).toUpperCase() +
                      retreatDetail.title.slice(1)
                  }),` +
                  ` Couple : (${
                    roasterdData && roasterdData.primaryKey
                      ? `${roasterdData.primaryKey} - `
                      : ""
                  }` +
                  `${
                    roasterdData && roasterdData.hisLastName
                      ? `${roasterdData.hisLastName}`
                      : ""
                  }` +
                  `${
                    roasterdData && roasterdData.hisFirstName
                      ? `, ${roasterdData.hisFirstName}`
                      : ""
                  }` +
                  `${
                    roasterdData && roasterdData.herFirstName
                      ? ` and  ${roasterdData.herFirstName}`
                      : ""
                  })`,
                action: "Add",
              };
              await adminApi
                .addLogReport(report)
                .then((response) => {
                  if (response.success) {
                    console.log("Created new log report.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              toast.success(res.msg);
              clearfield();
              remove1();
              // removeMarriagePhoto1();
              setShowModel(false);
              getRetreatRoastData();
              getRetreatRoastData1();
              leadCoupleData1();
              getCouplesInRetreat();
              setRoasterdData({
                id: "",
                hisFirstName: "",
                hisLastName: "",
                hisEmail: "",
                hisMobile: "",
                herFirstName: "",
                herLastName: "",
                herEmail: "",
                herMobile: "",
                city: "",
                state: "",
                zip: "",
                notes: "",
                imageUrl: "",
                // marriageImageUrl: "",
                attendeeType: "",
                roleId: null,
                serviceId: null,
                talkId: null,
                retreatId: retreatId,
              });
            } else {
              toast.error(res.msg);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    setValidated(true);
  };

  // const handleRoasterData = (e) => {
  //   const form = e.currentTarget;
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (form.checkValidity()) {
  //     if (
  //       ediUserIdClicked &&
  //       ediUserIdClicked !== "" &&
  //       ediUserIdClicked !== null
  //     ) {
  //       let data1 = new FormData();
  //       if (roasterdData.imageUrl !== "") {
  //         data1.append("file", roasterdData.imageUrl);
  //       }
  //       // if (roasterdData.marriageImageUrl !== "") {
  //       //   data1.append("marriage_file", roasterdData.marriageImageUrl);
  //       // }
  //       let stateRef = { ...roasterdData };
  //       // delete stateRef.imageUrl
  //       data1.append("fromData", JSON.stringify(stateRef));
  //       retreatRoastApi
  //         .updateRoaster(data1)
  //         .then(async (res) => {
  //           if (res.success) {
  //             console.log("res.data[0].id-->",res.data[0].id);
  //             userData.imageUpload = res.data[0].imageUrl;

  //             //userData.marriageImageUpload = res.data[0].marriageImageUrl;
  //             if (userData.coupleId === res.data[0].id) {
  //               setCookie("LoveStrongUser", userData, {
  //                 path: "/",
  //                 maxAge: 60 * 60 * 100,
  //                 expires: new Date(Date.now() + 60 * 60 * 100),
  //               });
  //             }
  //             toast.success(res.msg);
  //             if (
  //               oldData.hisFirstName ||
  //               (oldData.hisFirstName === "" &&
  //                 oldData.hisFirstName !== res.data[0].hisFirstName)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HisFirstName of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.hisFirstName ? oldData.hisFirstName : "Nil"
  //                   }" to "${
  //                     res.data[0].hisFirstName
  //                       ? res.data[0].hisFirstName
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }

  //             if (
  //               oldData.hisLastName ||
  //               (oldData.hisLastName === "" &&
  //                 oldData.hisLastName !== res.data[0].hisLastName)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HisLastName of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.hisLastName ? oldData.hisLastName : "Nil"
  //                   }" to "${
  //                     res.data[0].hisLastName ? res.data[0].hisLastName : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.hisEmail ||
  //               (oldData.hisEmail === "" &&
  //                 oldData.hisEmail !== res.data[0].hisEmail)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HisEmail of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.hisEmail ? oldData.hisEmail : "Nil"
  //                   }" to "${
  //                     res.data[0].hisEmail ? res.data[0].hisEmail : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.hisMobile ||
  //               (oldData.hisMobile === "" &&
  //                 oldData.hisMobile !== res.data[0].hisMobile)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HisMobile of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.hisMobile ? oldData.hisMobile : "Nil"
  //                   }" to "${
  //                     res.data[0].hisMobile ? res.data[0].hisMobile : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.herFirstName ||
  //               (oldData.herFirstName === "" &&
  //                 oldData.herFirstName !== res.data[0].herFirstName)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HerFirstName of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.herFirstName ? oldData.herFirstName : "Nil"
  //                   }" to "${
  //                     res.data[0].herFirstName
  //                       ? res.data[0].herFirstName
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.herLastName ||
  //               (oldData.herLastName === "" &&
  //                 oldData.herLastName !== res.data[0].herLastName)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HerLastName of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.herLastName ? oldData.herLastName : "Nil"
  //                   }" to "${
  //                     res.data[0].herLastName ? res.data[0].herLastName : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.herEmail ||
  //               (oldData.herEmail === "" &&
  //                 oldData.herEmail !== res.data[0].herEmail)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HerEmail of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.herEmail ? oldData.herEmail : "Nil"
  //                   }" to "${
  //                     res.data[0].herEmail ? res.data[0].herEmail : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.herMobile ||
  //               (oldData.herMobile === "" &&
  //                 oldData.herMobile !== res.data[0].herMobile)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated HerMobile of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.herMobile ? oldData.herMobile : "Nil"
  //                   }" to "${
  //                     res.data[0].herMobile ? res.data[0].herMobile : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.notes ||
  //               (oldData.notes === "" && oldData.notes !== res.data[0].notes)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated Notes of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${oldData.notes ? oldData.notes : "Nil"}" to "${
  //                     res.data[0].notes ? res.data[0].notes : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.zip ||
  //               (oldData.zip === "" && oldData.zip !== res.data[0].zip)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated Zip of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${oldData.zip ? oldData.zip : "Nil"}" to "${
  //                     res.data.zip ? res.data.zip : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.city ||
  //               (oldData.city === "" && oldData.city !== res.data[0].city)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated City of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${oldData.city ? oldData.city : "Nil"}" to "${
  //                     res.data.city ? res.data.city : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.state ||
  //               (oldData.state === "" && oldData.state !== res.data[0].state)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated State of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${oldData.state ? oldData.state : "Nil"}" to "${
  //                     res.data.state ? res.data.state : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.imageUrl ||
  //               (oldData.imageUrl === "" &&
  //                 oldData.imageUrl !== res.data[0].imageUrl)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated ImageUrl of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })`,

  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             // if (
  //             //   oldData.marriageImageUrl ||
  //             //   (oldData.marriageImageUrl === "" &&
  //             //     oldData.marriageImageUrl !== res.data[0].marriageImageUrl)
  //             // ) {
  //             //   let report = {
  //             //     byWhom:
  //             //       `${
  //             //         userData && userData.userName ? userData.userName : null
  //             //       } ` +
  //             //       ` (${
  //             //         userData && userData.userRole ? userData.userRole : null
  //             //       })`,
  //             //     pagelink: window.location.href,
  //             //     description:
  //             //       `Updated MarriageImageUrl of Couple in Retreat Roast ` +
  //             //       `(Retreat : ${
  //             //         retreatDetail &&
  //             //         retreatDetail.title &&
  //             //         retreatDetail.title.charAt(0).toUpperCase() +
  //             //           retreatDetail.title.slice(1)
  //             //       }), ` +
  //             //       `Couple : (${
  //             //         roasterdData.primaryKey
  //             //           ? `${roasterdData.primaryKey} - `
  //             //           : ""
  //             //       }` +
  //             //       `${
  //             //         roasterdData && roasterdData.hisLastName
  //             //           ? `${roasterdData.hisLastName}`
  //             //           : ""
  //             //       }` +
  //             //       `${
  //             //         roasterdData && roasterdData.hisFirstName
  //             //           ? `, ${roasterdData.hisFirstName}`
  //             //           : ""
  //             //       }` +
  //             //       `${
  //             //         roasterdData && roasterdData.herFirstName
  //             //           ? ` and  ${roasterdData.herFirstName}`
  //             //           : ""
  //             //       })`,

  //             //     action: "Update",
  //             //   };
  //             //   await adminApi
  //             //     .addLogReport(report)
  //             //     .then((response) => {
  //             //       if (response.success) {
  //             //         console.log("Created new log report.");
  //             //       }
  //             //     })
  //             //     .catch((error) => {
  //             //       console.log(error);
  //             //     });
  //             // }

  //             let oldRole = rolesDataList.find((i) => i.id === oldData.roleId);
  //             let newRole = rolesDataList.find(
  //               (i) => i.id === res.roastData.roleId
  //             );
  //             if (
  //               oldData.roleId !== undefined &&
  //               (oldData.roleId || oldData.roleId === null)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated Role of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldRole && oldRole.role && oldRole.role !== undefined
  //                       ? oldRole.role
  //                       : "Nil"
  //                   }" to "${
  //                     newRole && newRole.role && newRole.role !== undefined
  //                       ? newRole.role
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }

  //             let oldService = serviceDataList.find(
  //               (i) => i.id === oldData.serviceId
  //             );
  //             let newService = serviceDataList.find(
  //               (i) => i.id === res.roastData.serviceId
  //             );

  //             if (
  //               oldData.serviceId !== undefined &&
  //               (oldData.serviceId || oldData.serviceId === null)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated Service of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldService &&
  //                     oldService.service &&
  //                     oldService.service !== undefined
  //                       ? oldService.service
  //                       : "Nil"
  //                   }" to "${
  //                     newService &&
  //                     newService.service &&
  //                     newService.service !== undefined
  //                       ? newService.service
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             let oldTalk = talksDataList.find(
  //               (i) => i.talkId == oldData.talkId
  //             );
  //             let newTalk = talksDataList.find(
  //               (i) => i.talkId == res.roastData.talkId
  //             );
  //             if (
  //               oldData.talkId !== undefined &&
  //               (oldData.talkId || oldData.talkId === null)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated Talk of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldTalk && oldTalk.talk && oldTalk.talk !== undefined
  //                       ? oldTalk.talk
  //                       : "Nil"
  //                   }" to "${
  //                     newTalk && newTalk.talk && newTalk.talk !== undefined
  //                       ? newTalk.talk
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             if (
  //               oldData.attendeeType ||
  //               (oldData.attendeeType === "" &&
  //                 oldData.attendeeType !== res.roastData.attendeeType)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated AttendeeType of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldData.attendeeType ? oldData.attendeeType : "Nil"
  //                   }" to "${
  //                     res.roastData.attendeeType
  //                       ? res.roastData.attendeeType
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             let oldparish = parishList.find((i) => i.id === oldData.parishId);
  //             let newparish = parishList.find(
  //               (i) => i.id === res.data[0].parishId
  //             );
  //             if (
  //               oldData.parishId !== undefined &&
  //               (oldData.parishId || oldData.parishId === null)
  //             ) {
  //               let report = {
  //                 byWhom:
  //                   `${
  //                     userData && userData.userName ? userData.userName : null
  //                   } ` +
  //                   ` (${
  //                     userData && userData.userRole ? userData.userRole : null
  //                   })`,
  //                 pagelink: window.location.href,
  //                 description:
  //                   `Updated Parish of Couple in Retreat Roast ` +
  //                   `(Retreat : ${
  //                     retreatDetail &&
  //                     retreatDetail.title &&
  //                     retreatDetail.title.charAt(0).toUpperCase() +
  //                       retreatDetail.title.slice(1)
  //                   }), ` +
  //                   `Couple : (${
  //                     roasterdData.primaryKey
  //                       ? `${roasterdData.primaryKey} - `
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisLastName
  //                       ? `${roasterdData.hisLastName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.hisFirstName
  //                       ? `, ${roasterdData.hisFirstName}`
  //                       : ""
  //                   }` +
  //                   `${
  //                     roasterdData && roasterdData.herFirstName
  //                       ? ` and  ${roasterdData.herFirstName}`
  //                       : ""
  //                   })` +
  //                   ` From "${
  //                     oldparish &&
  //                     oldparish.parish &&
  //                     oldparish.parish !== undefined
  //                       ? oldparish.parish
  //                       : "Nil"
  //                   }" to "${
  //                     newparish &&
  //                     newparish.parish &&
  //                     newparish.parish !== undefined
  //                       ? newparish.parish
  //                       : "Nil"
  //                   }"`,
  //                 action: "Update",
  //               };
  //               await adminApi
  //                 .addLogReport(report)
  //                 .then((response) => {
  //                   if (response.success) {
  //                     console.log("Created new log report.");
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             setOldData(null);
  //             clearfield();
  //             setRoasterdData({
  //               id: "",
  //               hisFirstName: "",
  //               hisLastName: "",
  //               hisEmail: "",
  //               hisMobile: "",
  //               herFirstName: "",
  //               herLastName: "",
  //               herEmail: "",
  //               herMobile: "",
  //               city: "",
  //               state: "",
  //               zip: "",
  //               notes: "",
  //               imageUrl: "",
  //               // marriageImageUrl: "",
  //               attendeeType: "",
  //               roleId: null,
  //               serviceId: null,
  //               talkId: null,
  //               retreatId: retreatId,
  //             });
  //             setShowModel(false);
  //             getRetreatRoastData();
  //             getRetreatRoastData1();
  //             leadCoupleData1();
  //             setIsTempUpload(false);
  //             setIsNewCouple(false);
  //             getCouplesInRetreat();
  //           } else {
  //             toast.error(res.msg);
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error);
  //         });
  //     } else {
  //       let data1 = new FormData();
  //       data1.append("file", roasterdData.imageUrl);
  //       // data1.append("marriage_file", roasterdData.marriageImageUrl);
  //       let stateRef = { ...roasterdData };
  //       delete stateRef.imageUrl;
  //       // delete stateRef.marriageImageUrl;
  //       data1.append("fromData", JSON.stringify(stateRef));
  //       retreatRoastApi
  //         .CreateRoaster(data1)
  //         .then(async (res) => {
  //           if (res.success) {
  //             let report = {
  //               byWhom:
  //                 `${
  //                   userData && userData.userName ? userData.userName : null
  //                 } ` +
  //                 ` (${
  //                   userData && userData.userRole ? userData.userRole : null
  //                 })`,
  //               pagelink: window.location.href,
  //               description:
  //                 `Added new couple in Retreat Roast` +
  //                 ` (Retreat : ${
  //                   retreatDetail &&
  //                   retreatDetail.title &&
  //                   retreatDetail.title.charAt(0).toUpperCase() +
  //                     retreatDetail.title.slice(1)
  //                 }),` +
  //                 ` Couple : (${
  //                   roasterdData && roasterdData.primaryKey
  //                     ? `${roasterdData.primaryKey} - `
  //                     : ""
  //                 }` +
  //                 `${
  //                   roasterdData && roasterdData.hisLastName
  //                     ? `${roasterdData.hisLastName}`
  //                     : ""
  //                 }` +
  //                 `${
  //                   roasterdData && roasterdData.hisFirstName
  //                     ? `, ${roasterdData.hisFirstName}`
  //                     : ""
  //                 }` +
  //                 `${
  //                   roasterdData && roasterdData.herFirstName
  //                     ? ` and  ${roasterdData.herFirstName}`
  //                     : ""
  //                 })`,
  //               action: "Add",
  //             };
  //             await adminApi
  //               .addLogReport(report)
  //               .then((response) => {
  //                 if (response.success) {
  //                   console.log("Created new log report.");
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.log(error);
  //               });
  //             toast.success(res.msg);
  //             clearfield();
  //             remove1();
  //             setShowModel(false);
  //             getRetreatRoastData();
  //             getRetreatRoastData1();
  //             leadCoupleData1();
  //             getCouplesInRetreat();
  //             setRoasterdData({
  //               id: "",
  //               hisFirstName: "",
  //               hisLastName: "",
  //               hisEmail: "",
  //               hisMobile: "",
  //               herFirstName: "",
  //               herLastName: "",
  //               herEmail: "",
  //               herMobile: "",
  //               city: "",
  //               state: "",
  //               zip: "",
  //               notes: "",
  //               imageUrl: "",
  //               // marriageImageUrl: "",
  //               attendeeType: "",
  //               roleId: null,
  //               serviceId: null,
  //               talkId: null,
  //               retreatId: retreatId,
  //             });
  //           } else {
  //             toast.error(res.msg);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   }
  //   setValidated(true);
  // };

  const addRoaster = () => {
    setIsNewCouple(true);
    setShowModel(true);
    getPerishList();
    getrolesList();
    getServiceList();
    getTalksList();
    checkPKCouple();
  };

  const handleSelectCuple = async (val) => {
    if (val.value) {
      let d = { ...roasterdData };
      await adminApi
        .getCouplesDetail({ coupleId: val.value })
        .then(async (res) => {
          if (
            res.data[0].hisEmail === "tkolkebeck@me.com" ||
            res.data[0].herEmail === "tkolkebeck@satx.rr.com" ||
            res.data[0].hisEmail === "jimmieflores@gmail.com" ||
            res.data[0].herEmail === "dulceaflores@gmail.com"
          ) {
            setIsEditAdmin(true);
          }
          if (res.success) {
            // setEdiUserIdClicked(res.data[0].id);
            d.primaryKey = res.data[0].primaryKey;
            d.hisFirstName = res.data[0].hisFirstName;
            d.hisLastName = res.data[0].hisLastName;
            d.hisEmail = res.data[0].hisEmail;
            d.hisMobile = res.data[0].hisMobile;
            d.herFirstName = res.data[0].herFirstName;
            d.herLastName = res.data[0].herLastName;
            d.herEmail = res.data[0].herEmail;
            d.herMobile = res.data[0].herMobile;
            d.city = res.data[0].city;
            d.state = res.data[0].state;
            d.zip = res.data[0].zip;
            d.notes = res.data[0].notes;
            d.imageUrl = res.data[0].imageUrl;
            // d.marriageImageUrl = res.data[0].marriageImageUrl;
            d.parishId = res.data[0].parishId;
            d.coupleId = res.data[0].id;
            setRoasterdData(d);
          } else {
            toast.error(res.msg);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const renderEditRetreatModel = () => {
    return (
      <Modal
        show={showModel}
        onHide={() => {
          setShowModel(false);
          setEdiUserIdClicked("");
          setRoasterdData("");
          setEdiUserIdClicked("");
        }}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {retreatDetail && retreatDetail.title
              ? `${
                  retreatDetail &&
                  retreatDetail.title &&
                  retreatDetail.title.charAt(0).toUpperCase() +
                    retreatDetail.title.slice(1)
                }, `
              : null}
            {retreatDetail.dateFrom == retreatDetail.dateTo
              ? moment(retreatDetail && retreatDetail.dateFrom).format(
                  "MMM-DD-YYYY"
                )
              : moment(retreatDetail && retreatDetail.dateFrom).format(
                  "MMM DD"
                ) +
                " - " +
                moment(retreatDetail && retreatDetail.dateTo).format("MMM DD") +
                ", " +
                moment(retreatDetail && retreatDetail.dateTo).format("YYYY")}
          </Modal.Title>
        </Modal.Header>

        <Form
          onSubmit={handleRoasterData}
          noValidate
          validated={validated}
          className="forms-sample"
        >
          <Modal.Body>
            <Row>
              {isNewCouple ? (
                <Col lg={6} md={6} xs={12}>
                  <Tabs
                    defaultActiveKey="AddNew"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="AddNew" title=" Add New">
                      Add New Couple
                    </Tab>

                    <Tab eventKey="AddOld" title="Add Existing Couple">
                      <label htmlFor="type" className="bold">
                        Select Couple from existing Couples list.
                      </label>
                      <Select
                        name="Couples"
                        className="mt-3"
                        options={allCoupleList}
                        // value={selectedLocation}
                        onChange={handleSelectCuple}
                        labelledBy="Select"
                        isSearchable={true}
                        isMulti={false}
                      />
                    </Tab>
                  </Tabs>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label>Couple Id</Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    name="primaryKey"
                    id="primaryKey"
                    placeholder="Couple Id"
                    value={roasterdData.primaryKey}
                    className="form-control"
                    onChange={(e) => handleChangeRoaster(e)}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    Couple Id is required!
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="mt-4">
                  {" "}
                  <label className="bold mt-2" htmlFor="exampleInputEmail1">
                    If you or your spouse are 35 years of age or younger, click
                    this box:
                  </label>
                  <div className="form-check-label mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input ageLabel"
                      name="under_age_35"
                      checked={
                        roasterdData.under_age_35 === "YES" ? true : false
                      }
                      onChange={(e) => {
                        handleAgeChanges(e, "forRoaster");
                      }}
                    />
                    Yes
                    <i className="input-helper"></i>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  {roasterdData.imageUrl == "" ||
                  roasterdData.imageUrl == undefined ? (
                    <div className="edit-profile-img mb-2 text-center">
                      <img src={Profile} />
                    </div>
                  ) : (
                    <div className="edit-profile-img mb-2 text-center">
                      {isTempUpload ? (
                        <>
                          <img
                            src={URL.createObjectURL(roasterdData?.imageUrl)}
                          />
                        </>
                      ) : (
                        <>
                          <img src={roasterdData?.imageUrl} />
                        </>
                      )}
                    </div>
                  )}{" "}
                  <div className="text-center mt-3">
                    {roasterdData.imageUrl == "" ||
                    roasterdData.imageUrl == undefined ? (
                      <>
                        <input
                          name="imageUrl"
                          className="pt-1"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          id="CoupleProfileUpload"
                          onChange={(e) => handleChangeRoaster(e)}
                        />
                        <Button
                          variant="primary"
                          className="mb-1 me-3 text-center"
                          onClick={(e) => {
                            upload();
                          }}
                        >
                          Upload Profile Image
                        </Button>
                        {/* <span className="text-small d-block me-3">(Upload Square Image)</span> */}
                      </>
                    ) : (
                      <Button
                        variant="danger"
                        className="mb-1 mt-2 "
                        onClick={(e) => {
                          remove1();
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </Col>
              {/* <Col lg={3} md={3} xs={12}>
                <Form.Group>
                  {roasterdData.marriageImageUrl == "" ||
                  roasterdData.marriageImageUrl == undefined ? (
                    <div className="edit-profile-img mb-2 text-center">
                      <img src={Profile} />
                    </div>
                  ) : (
                    <div className="edit-profile-img mb-2 text-center">
                      {isTempUpload2 ? (
                        <>
                          <img
                            src={URL.createObjectURL(
                              roasterdData?.marriageImageUrl
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <img src={roasterdData?.marriageImageUrl} />
                        </>
                      )}
                    </div>
                  )}
                  <div className="text-center mt-3">
                    {roasterdData.marriageImageUrl == "" ||
                    roasterdData.marriageImageUrl == undefined ? (
                      <>
                        <input
                          name="marriageImageUrl"
                          className="pt-1"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          id="MarriagePhotoUpload"
                          onChange={(e) => handleChangeRoaster(e)}
                        />
                        <Button
                          variant="primary"
                          className="mb-1 text-center"
                          onClick={(e) => {
                            uploadMarriagePhoto();
                          }}
                        >
                          Upload Wedding Image
                        </Button>
                      </>
                    ) : userData && userData.userRole === "SUBADMIN" ? null : (
                      <Button
                        variant="danger"
                        className="mb-1 mt-2 "
                        onClick={(e) => {
                          removeMarriagePhoto1();
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </Col> */}
            </Row>
            <hr></hr>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <label className="badge badge-outline-primary rounded hisLabel">
                  His Info
                </label>
                <div id="his-info">
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="hisFirstName"
                        id="hisFirstName"
                        placeholder="First Name"
                        value={roasterdData.hisFirstName}
                        className="form-control"
                        onChange={(e) => handleChangeRoaster(e)}
                        readOnly={isEditAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        FirstName is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="hisLastName"
                        id="hisLastName"
                        placeholder="First Name"
                        value={roasterdData.hisLastName}
                        className="form-control"
                        onChange={(e) => handleChangeRoaster(e)}
                        readOnly={isEditAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        LastName is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group col">
                      <Form.Label>His Email</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="hisEmail"
                        id="hisEmail"
                        placeholder="Email"
                        value={roasterdData.hisEmail}
                        onChange={(e) => handleChangeRoaster(e)}
                        readOnly={isEditAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        Email is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group col">
                      <Form.Label>His Mobile</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="hisMobile"
                        id="hisMobile"
                        placeholder="Mobile"
                        value={roasterdData.hisMobile}
                        onChange={(e) => handleChangeRoaster(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Mobile is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </div>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <label className="badge badge-outline-primary rounded herLabel">
                  Her Info
                </label>
                <div id="her-info">
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="herFirstName"
                        id="herFirstName"
                        placeholder="First Name"
                        value={roasterdData.herFirstName}
                        onChange={(e) => handleChangeRoaster(e)}
                        readOnly={isEditAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        FirstName is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="herLastName"
                        id="herLastName"
                        placeholder="Last Name"
                        value={roasterdData.herLastName}
                        onChange={(e) => handleChangeRoaster(e)}
                        readOnly={isEditAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        LastName is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="herEmail"
                        id="herEmail"
                        placeholder="Email"
                        value={roasterdData.herEmail}
                        onChange={(e) => handleChangeRoaster(e)}
                        readOnly={isEditAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        Email is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} xs={12}>
                    <Form.Group className="form-group">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        // required
                        size="sm"
                        type="text"
                        name="herMobile"
                        id="herMobile"
                        placeholder="Mobile"
                        value={roasterdData.herMobile}
                        onChange={(e) => handleChangeRoaster(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Mobile is required!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <div className="row">
              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={roasterdData.address}
                  onChange={(e) => handleChangeRoaster(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Address is required!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>State</Form.Label>
                <Form.Select
                  className="form-control minimal"
                  size="sm"
                  type="text"
                  name="state"
                  id="state"
                  placeholder="State"
                  value={roasterdData.state}
                  onChange={(e) => handleChangeRoaster(e)}
                >
                  <option value="">Select State</option>
                  <option value="Alaska">Alaska</option>
                  <option value="Alabama">Alabama</option>
                  <option value="Arkansas">Arkansas</option>
                  <option value="Arizona">Arizona</option>
                  <option value="California">California</option>
                  <option value="Colorado">Colorado</option>
                  <option value="Connecticut">Connecticut</option>
                  <option value="District of Columbia">
                    District of Columbia
                  </option>
                  <option value="Delaware">Delaware</option>
                  <option value="Florida">Florida</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Iowa">Iowa</option>
                  <option value="Idaho">Idaho</option>
                  <option value="Illinois">Illinois</option>
                  <option value="Indiana">Indiana</option>
                  <option value="Kansas">Kansas</option>
                  <option value="Kentucky">Kentucky</option>
                  <option value="Louisiana">Louisiana</option>
                  <option value="Massachusetts">Massachusetts</option>
                  <option value="Maryland">Maryland</option>
                  <option value="Maine">Maine</option>
                  <option value="Michigan">Michigan</option>
                  <option value="Minnesota">Minnesota</option>
                  <option value="Missouri">Missouri</option>
                  <option value="Mississippi">Mississippi</option>
                  <option value="Montana">Montana</option>
                  <option value="North Carolina<">North Carolina</option>
                  <option value="North Dakota">North Dakota</option>
                  <option value="Nebraska">Nebraska</option>
                  <option value="New Hampshire">New Hampshire</option>
                  <option value="New Jersey">New Jersey</option>
                  <option value="New Mexico">New Mexico</option>
                  <option value="Nevada">Nevada</option>
                  <option value="New York">New York</option>
                  <option value="Ohio">Ohio</option>
                  <option value="Oklahoma">Oklahoma</option>
                  <option value="Oregon">Oregon</option>
                  <option value="Pennsylvania">Pennsylvania</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Rhode Island">Rhode Island</option>
                  <option value="South Carolina">South Carolina</option>
                  <option value="South Dakota">South Dakota</option>
                  <option value="Tennessee">Tennessee</option>
                  <option value="Texas">Texas</option>
                  <option value="Utah">Utah</option>
                  <option value="Virginia">Virginia</option>
                  <option value="Vermont">Vermont</option>
                  <option value="Washington">Washington</option>
                  <option value="Wisconsin">Wisconsin</option>
                  <option value="West Virginia">West Virginia</option>
                  <option value="Wyoming">Wyoming</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  State is required!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>City</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  value={roasterdData.city}
                  onChange={(e) => handleChangeRoaster(e)}
                />
                <Form.Control.Feedback type="invalid">
                  City is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="zip"
                  id="zip"
                  placeholder="Zip"
                  value={roasterdData.zip}
                  onChange={(e) => handleChangeRoaster(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Zip is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>Parish</Form.Label>
                <Form.Select
                  className="form-control minimal"
                  size="sm"
                  type="text"
                  name="parishId"
                  id="parishId"
                  placeholder="Parish"
                  value={roasterdData.parishId}
                  onChange={(e) => handleChangeRoaster(e)}
                >
                  <option value="">Select Parish</option>
                  {parishList.map((item, index) => {
                    return <option value={item.id}>{item.parish}</option>;
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Parsih is required!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label className="d-block">
                  Anniversary Date (Month/Day)
                </Form.Label>

                <DatePicker
                  // required
                  autoComplete="off"
                  dateFormat="MM-dd-yyyy"
                  placeholderText="mm-dd-yyyy"
                  selected={roasterdData && roasterdData.anniversary_date}
                  onChange={(e) => handleDatePicker(e, "forRoaster")}
                  className="form-select setdate datepicker"
                  name="anniversary_date"
                  id="anniversary_date"
                  maxDate={new Date()}
                  showDisabledMonthNavigation
                />
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  value={roasterdData.notes}
                  onChange={(e) => handleChangeRoaster(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Notes is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Form.Label>
                  Do you or your spouse have any dietary allergies, dietary
                  restrictions or physical limitations?
                </Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="allergies"
                  id="allergies"
                  value={roasterdData.allergies}
                  onChange={(e) => handleChangeRoaster(e)}
                />
              </Form.Group>
            </div>

            <div className="row mt-3">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h4>Please provide at least one emergency contact:</h4>
              </div>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="emergency_name1"
                  id="emergency_name1"
                  placeholder="Name"
                  value={roasterdData.emergency_name1}
                  onChange={(e) => handleChangeRoaster(e)}
                />
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <Form.Label>Relationship</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="emergency_relationship1"
                  id="emergency_relationship1"
                  placeholder="Relationship"
                  value={roasterdData.emergency_relationship1}
                  onChange={(e) => handleChangeRoaster(e)}
                />
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="emergency_phone1"
                  id="emergency_phone1"
                  placeholder="Phone"
                  value={roasterdData.emergency_phone1}
                  onChange={(e) => handleChangeRoaster(e)}
                />
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="emergency_name2"
                  id="emergency_name2"
                  placeholder="Name"
                  value={roasterdData.emergency_name2}
                  onChange={(e) => handleChangeRoaster(e)}
                />
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <Form.Label>Relationship</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="emergency_relationship2"
                  id="emergency_relationship2"
                  placeholder="Relationship"
                  value={roasterdData.emergency_relationship2}
                  onChange={(e) => handleChangeRoaster(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Relationship is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="emergency_phone2"
                  id="emergency_phone2"
                  placeholder="Phone"
                  value={roasterdData.emergency_phone2}
                  onChange={(e) => handleChangeRoaster(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Phone is required!
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row mt-3 ">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h4>How did you hear about us?</h4>
              </div>
              <div className="d-flex flex-wrap justify-content-between hear-options">
                <label className="form-check-label ml-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input ageLabel"
                    name="Website"
                    value="Website"
                    onChange={(e) => handleCheckboxChange(e, "forRoaster")}
                    checked={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes("Website")
                    }
                    disabled={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes(
                        roasterdData.hear_us === "Website"
                      )
                    }
                  />
                  Website
                  <i className="input-helper"></i>
                </label>

                <label className="form-check-label ml-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input ageLabel"
                    name="Church Bulletin"
                    value="Church Bulletin"
                    onChange={(e) => handleCheckboxChange(e, "forRoaster")}
                    checked={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes("Church Bulletin")
                    }
                    disabled={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes(
                        roasterdData.hear_us === "Church Bulletin"
                      )
                    }
                  />
                  Church Bulletin
                  <i className="input-helper"></i>
                </label>

                <label className="form-check-label ml-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input ageLabel"
                    name="Facebook"
                    value="Facebook"
                    onChange={(e) => handleCheckboxChange(e, "forRoaster")}
                    checked={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes("Facebook")
                    }
                    disabled={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes(
                        roasterdData.hear_us === "Facebook"
                      )
                    }
                  />
                  Facebook
                  <i className="input-helper"></i>
                </label>

                <label className="form-check-label ml-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input ageLabel"
                    name="School Marquee"
                    value="School Marquee"
                    onChange={(e) => handleCheckboxChange(e, "forRoaster")}
                    checked={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes("School Marquee")
                    }
                    disabled={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes(
                        roasterdData.hear_us === "School Marquee"
                      )
                    }
                  />
                  School Marquee
                  <i className="input-helper"></i>
                </label>

                <label className="form-check-label ml-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input ageLabel"
                    name="Pulpit Talk/Table in Church"
                    value="Pulpit Talk/Table in Church"
                    onChange={(e) => handleCheckboxChange(e, "forRoaster")}
                    checked={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes(
                        "Pulpit Talk/Table in Church"
                      )
                    }
                    disabled={
                      roasterdData &&
                      roasterdData.hear_us &&
                      roasterdData.hear_us.includes(
                        roasterdData.hear_us === "Pulpit Talk/Table in Church"
                      )
                    }
                  />
                  Pulpit Talk/Table in Church
                  <i className="input-helper"></i>
                </label>
              </div>
            </div>
            <Col lg={6} md={6} xs={12} sm={12} className=" mt-5">
              <h4> Referral from a friend/Family member</h4>
              <Form.Group className="form-group">
                <Form.Control
                  // required
                  size="sm"
                  type="text"
                  name="referral_from_a_friend_or_family_member"
                  placeholder=" Referral from a friend/Family member"
                  id="referral_from_a_friend_or_family_member"
                  value={roasterdData.referral_from_a_friend_or_family_member}
                  className="form-control"
                  onChange={(e) => {
                    handleChangeRoaster(e);
                  }}
                />
              </Form.Group>
            </Col>
            <hr></hr>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <Form.Group className="form-group col">
                  <Form.Label>
                    Attendee Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="form-control minimal"
                    size="sm"
                    type="text"
                    name="attendeeType"
                    id="attendeeType"
                    value={roasterdData && roasterdData.attendeeType}
                    onChange={(e) => handleChangeRoaster(e)}
                    required
                  >
                    <option value="">Select Attendee Type</option>
                    <option value="Team">Team</option>
                    <option value="Retreatant">Retreatant</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Attendee Type is required!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group className="form-group col">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    className="form-control minimal"
                    size="sm"
                    type="text"
                    name="roleId"
                    id="roleId"
                    value={roasterdData && roasterdData.roleId}
                    onChange={(e) => handleChangeRoaster(e)}
                  >
                    <option value="">No Role</option>
                    {rolesDataList.length
                      ? rolesDataList.map((item) => {
                          return (
                            <option key={`role-${item.id}`} value={item.id}>
                              {item.role}
                            </option>
                          );
                        })
                      : " "}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group className="form-group col">
                  <Form.Label>Service Title</Form.Label>
                  <Form.Select
                    className="form-control minimal"
                    size="sm"
                    type="text"
                    name="serviceId"
                    id="serviceId"
                    value={roasterdData && roasterdData.serviceId}
                    onChange={(e) => handleChangeRoaster(e)}
                  >
                    <option value="">No Service Title</option>
                    {serviceDataList.length
                      ? serviceDataList.map((item) => {
                          return (
                            <option
                              key={`serviceTitle-${item.id}`}
                              value={item.id}
                            >
                              {item.service}
                            </option>
                          );
                        })
                      : " "}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Service is required!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group className="form-group col">
                  <Form.Label>Talk</Form.Label>
                  <Form.Select
                    className="form-control minimal"
                    size="sm"
                    type="text"
                    name="talkId"
                    id="talkId"
                    value={roasterdData && roasterdData.talkId}
                    onChange={(e) => handleChangeRoaster(e)}
                  >
                    <option value="">No Talk</option>
                    {talksDataList.length
                      ? talksDataList.map((item) => {
                          return (
                            <option
                              key={`talk-${item.talkId}`}
                              value={item.talkId}
                            >
                              {item.talk}
                            </option>
                          );
                        })
                      : " "}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Talk is required!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" type="submit">
              {ediUserIdClicked && ediUserIdClicked !== ""
                ? "Update"
                : "Submit"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModel(false);
                setEdiUserIdClicked("");
                setRoasterdData("");
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  const serviceData = async () => {
    let d = {};
    d.retreatId = retreatId;
    await retreatRoastApi
      .getServiceRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const talkData = async () => {
    let d = {};
    d.retreatId = retreatId;
    await retreatRoastApi
      .gettalkRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const RetreatData = async () => {
    let d = {};
    d.retreatId = retreatId;
    await retreatRoastApi
      .getRetreatantRoastData(d)
      .then((res) => {
        if (res.success) {
          setRetreatRoastData(res.data);
          setTotal(res.totalRecords);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const getRetreatRoastData1 = async (
    pageNo = 1,
    size = 20,
    searchKeyword = null,
    sortBy = {},
    loadCount = false
  ) => {
    let d = {};
    if (pageNo && size) {
      d.pageNo = pageNo;
      d.size = size;
    }
    if (searchKeyword !== null) {
      d.searchKeyword = searchKeyword;
    } else {
      d.searchKeyword = searchKey;
    }
    if (sortBy.sortColumn) {
      d.sortBy = sortBy;
    }
    d.export = true;
    d.retreatId = retreatId;
    setLoadData(true);
    await retreatRoastApi
      .getRetreatRoastData(d)
      .then((res) => {
        if (res.success) {
          let totalRecords = res.totalRecords ? res.totalRecords : 0;
          let spcount = 0;
          let lccount = 0;
          res.data.map((i) => {
            if (i.role === "Spiritual Mentor") {
              spcount = spcount + 1;
            }
            if (i.role === "Lead Couple") {
              lccount = lccount + 1;
            }
          });
          setSpritual_count(spcount);
          setLead_count(lccount);
          setLoadData(false);
        } else {
          setLoadData(false);
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        console.error("There is an error!", error);
        toast.error(error);
      });
  };

  const getRetreatDetail = async () => {
    setLoadData(true);
    await retreatApi
      .getRetreatDetail({ id: retreatId })
      .then(async (response) => {
        const { schedule, ...rest } = response.data[0];
        const parsedSchedule = schedule ? JSON.parse(schedule) : [];
        const parsedData = {
          ...rest,
          schedule: parsedSchedule.map((entry) => ({
            date: new Date(entry.date),
            from: entry.from,
            to: entry.to,
          })),
        };
        setRetreatDetail(parsedData);
        setLoadData(false);
      })
      .catch((error) => {
        setLoadData(false);
        toast.error(error.toString());
      });
  };

  const DeleteData = async () => {
    await retreatRoastApi
      .deleteRetreatRoast({ id: roast_id })
      .then(async (response) => {
        getRetreatRoastData();
        setShow(false);
        setRoast_id("");
        leadCoupleData1();
        toast.success(response.msg);
        let report = {
          byWhom:
            `${userData && userData.userName ? userData.userName : null} ` +
            ` (${userData && userData.userRole ? userData.userRole : null})`,
          pagelink: window.location.href,
          description:
            `Deleted Retreat Roaster ( Retreat : ${
              retreatDetail &&
              retreatDetail.title &&
              retreatDetail.title.charAt(0).toUpperCase() +
                retreatDetail.title.slice(1)
            } ) , ( Couple : ${
              roasterdData && roasterdData.primaryKey
                ? `${roasterdData.primaryKey} - `
                : ""
            }` +
            `${
              roasterdData && roasterdData.hisLastName
                ? `${roasterdData.hisLastName}`
                : ""
            }` +
            `${
              roasterdData && roasterdData.hisFirstName
                ? `, ${roasterdData.hisFirstName}`
                : ""
            }` +
            `${
              roasterdData && roasterdData.herFirstName
                ? ` and  ${roasterdData.herFirstName}`
                : ""
            } ) `,
          action: "Delete",
        };
        await adminApi
          .addLogReport(report)
          .then((response) => {
            if (response.success) {
              console.log("Created new log report.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setShow(false);
        setRoast_id("");
        leadCoupleData1();
        toast.error(error.toString());
      });
  };

  const clickOnInputFile = () => {
    document.getElementById("fileUpload").click();
  };

  function isXLSXFile(file) {
    return file.name.endsWith(".xlsx");
  }

  const fileChangeListener = (event) => {
    let files = [];
    files = event.target.files;
    if (files && files.length && isXLSXFile(files[0])) {
      const input = event.target;
      const reader = new FileReader();
      reader.readAsBinaryString(input.files[0]);

      reader.onload = (e) => {
        const xlsxData = reader.result;
        processXLSXContactRecord(xlsxData);
      };
      reader.onerror = function () {
        alert("Unable to read " + input.files[0]);
        toast.error(`Unable to read ${input.files[0]} `);
        // setProcessing(false);
      };
    } else {
      toast.error("Please import valid .xlsx file.");
      // this.errorMsg = "Please import valid .xlsx file.";
      // fileReset();
    }
  };

  const ImportFileHeader = {
    A: "Couple Id",
    B: "Type",
    C: "His Last Name",
    D: "His First Name",
    E: "Her Last Name",
    F: "Her First Name",
    G: "His Email",
    H: "Her Email",
    I: "His Mobile",
    J: "Her Mobile",
    K: "Service Role",
    L: "Service Title",
    M: "Talk ",
    N: "Address",
    O: "City",
    P: "State",
    Q: "Zip",
    R: "Parish",
    S: "Anniversary Date",
    T: "Dietary Restrictions Or Physical Limitations",
    U: "Under Age 35",
    V: "Emergency Name1",
    W: "Emergency Relationship1",
    X: "Emergency Phone1",
    Y: "Emergency Name2",
    Z: "Emergency Relationship2",
    AA: "Emergency Phone2",
    AB: "How did you hear about us?",
    AC: "Referral from a friend/family member",
    AD: "Notes",
  };

  const processXLSXContactRecord = (xlsxRecordsArray) => {
    try {
      const workbook = XLSX.read(xlsxRecordsArray, {
        type: "binary",
        cellDates: true,
      });
      const sheet_name_list = workbook.SheetNames;
      const data = [];
      const headers = {};
      sheet_name_list.forEach(function (y) {
        const worksheet = workbook.Sheets[y];
        for (let z in worksheet) {
          if (z[0] === "!") continue;
          let tt = 0;
          for (let i = 0; i < z.length; i++) {
            if (!isNaN(z[i])) {
              tt = i;
              break;
            }
          }
          const col = z.substring(0, tt);
          const row = parseInt(z.substring(tt));
          const value = worksheet[z].v;
          if (row == 1 && value) {
            headers[col] = value.trim();
            continue;
          }
          if (!data[row]) {
            data[row] = {};
          }
          data[row][headers[col]] = value;
        }
      });
      const importFileHeaderKeys = Object.keys(ImportFileHeader).map((key) =>
        key.toUpperCase()
      );
      const headersKeys = Object.keys(headers).map((key) => key.toUpperCase());

      if (
        JSON.stringify(importFileHeaderKeys) === JSON.stringify(headersKeys)
      ) {
        if (data && data.length) {
          let importedData = [];
          // if (!data[2]["Couple Id"]) {
          //   toast.error("Couple Id Header is missing in Excel");
          //   return false;
          // }

          let typeSpellError = false;

          for (let index = 2; index < data.length; index++) {
            if (data[index].Type != "Team") {
              if (data[index].Type != "Retreatant") {
                typeSpellError = true;
                break;
              }
            }
          }

          if (typeSpellError) {
            toast.error(
              "There is error in 'Type' column. \n Check spelling of 'Retreatant' or 'Team'"
            );
            return;
          } else {
            data.forEach(function (value, index) {
              const obj = {};
              if (value["Couple Id"] == "") {
                toast.error(
                  "Couple Id value missing. Check near line " + index + 1
                );
                return false;
              }
              if (value["Couple Id"]) {
                obj.primaryKey = value["Couple Id"] ? value["Couple Id"] : "";
                obj.attendeeType = value["Type"] ? value["Type"] : "";
                obj.hisFirstName = value["His First Name"]
                  ? value["His First Name"]
                  : "";
                obj.hisLastName = value["His Last Name"]
                  ? value["His Last Name"]
                  : "";
                obj.hisEmail = value["His Email"] ? value["His Email"] : "";
                obj.hisMobile = value["His Mobile"] ? value["His Mobile"] : "";
                obj.herFirstName = value["Her First Name"]
                  ? value["Her First Name"]
                  : "";
                obj.herLastName = value["Her Last Name"]
                  ? value["Her Last Name"]
                  : "";
                obj.herEmail = value["Her Email"] ? value["Her Email"] : "";
                obj.herMobile = value["Her Mobile"] ? value["Her Mobile"] : "";
                obj.role = value["Service Role"] ? value["Service Role"] : "";
                obj.service = value["Service Title"]
                  ? value["Service Title"]
                  : "";
                obj.talk = value["Talk"] ? value["Talk"] : "";
                obj.address = value["Address"] ? value["Address"] : "";
                obj.city = value["City"] ? value["City"] : "";
                obj.state = value["State"] ? value["State"] : "";
                obj.zip = value["Zip"] ? value["Zip"] : "";
                obj.parish = value["Parish"] ? value["Parish"] : "";

                obj.anniversary_date = value["Anniversary Date"]
                  ? Helpers.convertUtcToTimezone(
                      value["Anniversary Date"],
                      "MM-DD"
                    )
                  : "";
                obj.allergies = value[
                  "Dietary Restrictions Or Physical Limitations"
                ]
                  ? value["Dietary Restrictions Or Physical Limitations"]
                  : "";
                obj.under_age_35 = value["Under Age 35"]
                  ? value["Under Age 35"]
                  : "";
                obj.emergency_name1 = value["Emergency Name1"]
                  ? value["Emergency Name1"]
                  : "";
                obj.emergency_relationship1 = value["Emergency Relationship1"]
                  ? value["Emergency Relationship1"]
                  : "";
                obj.emergency_phone1 = value["Emergency Phone1"]
                  ? value["Emergency Phone1"]
                  : "";
                obj.emergency_name2 = value["Emergency Name2"]
                  ? value["Emergency Name2"]
                  : "";
                obj.emergency_relationship2 = value["Emergency Relationship2"]
                  ? value["Emergency Relationship2"]
                  : "";
                obj.emergency_phone2 = value["Emergency Phone2"]
                  ? value["Emergency Phone2"]
                  : "";

                obj.hear_us =
                  value["How did you hear about us?"] &&
                  value["How did you hear about us?"] !== ""
                    ? [value["How did you hear about us?"]]
                    : [];

                obj.referral_from_a_friend_or_family_member = value[
                  "Referral from a friend/family member"
                ]
                  ? value["Referral from a friend/family member"]
                  : "";

                obj.notes = value["Notes"] ? value["Notes"] : "";
                obj.retreatId = retreatId;
              } else {
                obj.primaryKey = "";
                obj.attendeeType = value["Type"] ? value["Type"] : "";
                obj.hisFirstName = value["His First Name"]
                  ? value["His First Name"]
                  : "";
                obj.hisLastName = value["His Last Name"]
                  ? value["His Last Name"]
                  : "";
                obj.hisEmail = value["His Email"] ? value["His Email"] : "";
                obj.hisMobile = value["His Mobile"] ? value["His Mobile"] : "";
                obj.herFirstName = value["Her First Name"]
                  ? value["Her First Name"]
                  : "";
                obj.herLastName = value["Her Last Name"]
                  ? value["Her Last Name"]
                  : "";
                obj.herEmail = value["Her Email"] ? value["Her Email"] : "";
                obj.herMobile = value["Her Mobile"] ? value["Her Mobile"] : "";

                obj.role = value["Service Role"] ? value["Service Role"] : "";
                obj.service = value["Service Title"]
                  ? value["Service Title"]
                  : "";
                obj.talk = value["Talk"] ? value["Talk"] : "";
                obj.address = value["Address"] ? value["Address"] : "";
                obj.city = value["City"] ? value["City"] : "";
                obj.state = value["State"] ? value["State"] : "";
                obj.zip = value["Zip"] ? value["Zip"] : "";
                obj.parish = value["Parish"] ? value["Parish"] : "";
                obj.anniversary_date = value["Anniversary Date"]
                  ? Helpers.convertUtcToTimezone(
                      value["Anniversary Date"],
                      "MM-DD"
                    )
                  : "";
                obj.allergies = value[
                  "Dietary Restrictions Or Physical Limitations"
                ]
                  ? value["Dietary Restrictions Or Physical Limitations"]
                  : "";
                obj.under_age_35 = value["Under Age 35"]
                  ? value["Under Age 35"]
                  : "";
                obj.emergency_name1 = value["Emergency Name1"]
                  ? value["Emergency Name1"]
                  : "";
                obj.emergency_relationship1 = value["Emergency Relationship1"]
                  ? value["Emergency Relationship1"]
                  : "";
                obj.emergency_phone1 = value["Emergency Phone1"]
                  ? value["Emergency Phone1"]
                  : "";
                obj.emergency_name2 = value["Emergency Name2"]
                  ? value["Emergency Name2"]
                  : "";
                obj.emergency_relationship2 = value["Emergency Relationship2"]
                  ? value["Emergency Relationship2"]
                  : "";
                obj.emergency_phone2 = value["Emergency Phone2"]
                  ? value["Emergency Phone2"]
                  : "";

                obj.hear_us =
                  value["How did you hear about us?"] &&
                  value["How did you hear about us?"] !== ""
                    ? [value["How did you hear about us?"]]
                    : [];

                obj.referral_from_a_friend_or_family_member = value[
                  "Referral from a friend/family member"
                ]
                  ? value["Referral from a friend/family member"]
                  : "";

                obj.notes = value["Notes"] ? value["Notes"] : "";

                obj.retreatId = retreatId;
              }
              importedData.push(obj);
            });
            saveRetreatRoasterExcel(importedData);
          }
        }
      } else {
        toast.error("Headers are not the same. Please check sample file.");
        return;
      }
    } catch (err) {
      toast.error(err.toString());
    }
  };

  const saveRetreatRoasterExcel = async (importedData) => {
    setLoadData(true);
    const chunkSize = 10000;
    for (let i = 0; i < importedData.length; i += chunkSize) {
      const chunk = importedData.slice(i, i + chunkSize);
      await retreatRoastApi
        .saveRetreatRoasterExcel(chunk)
        .then(async (response) => {
          if (response.success) {
            if (response.createdCouple && response.createdCouple.length > 0) {
              await Promise.all(
                response.createdCouple.map(async (item) => {
                  let report = {
                    byWhom:
                      `${
                        userData && userData.userName ? userData.userName : null
                      } ` +
                      ` (${
                        userData && userData.userRole ? userData.userRole : null
                      })`,
                    pagelink: window.location.href,

                    description: ` New Couple (${item.couple}) added through Excel file import (Retreat id : ${retreatId})`,
                    action: "Click",
                  };
                  await adminApi
                    .addLogReport(report)
                    .then((response) => {
                      if (response.success) {
                        console.log("Created new log report.");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
              );
            }

            if (response.updateCouple && response.updateCouple.length > 0) {
              await Promise.all(
                response.updateCouple.map(async (item) => {
                  if (item.oldherFirstName != item.newherFirstName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated herFirstName from "${
                        item && item.oldherFirstName
                          ? item.oldherFirstName
                          : "Nil"
                      }" to "${
                        item && item.newherFirstName
                          ? item.newherFirstName
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldherLastName != item.newherLastName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated herLastName from "${
                        item && item.oldherLastName
                          ? item.oldherLastName
                          : "Nil"
                      }" to "${
                        item && item.newherLastName
                          ? item.newherLastName
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldherEmail != item.newherEmail) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated herEmail from "${
                        item && item.oldherEmail ? item.oldherEmail : "Nil"
                      }" to "${
                        item && item.newherEmail ? item.newherEmail : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldherMobile != item.newherMobile) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated herMobile from "${
                        item && item.oldherMobile ? item.oldherMobile : "Nil"
                      }" to "${
                        item && item.newherMobile ? item.newherMobile : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisFirstName != item.newhisFirstName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated hisFirstName from "${
                        item && item.oldhisFirstName
                          ? item.oldhisFirstName
                          : "Nil"
                      }" to "${
                        item && item.newhisFirstName
                          ? item.newhisFirstName
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisLastName != item.newhisLastName) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated hisLastName from "${
                        item && item.oldhisLastName
                          ? item.oldhisLastName
                          : "Nil"
                      }" to "${
                        item && item.newhisLastName
                          ? item.newhisLastName
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisEmail != item.newhisEmail) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated hisEmail from "${
                        item && item.oldhisEmail ? item.oldhisEmail : "Nil"
                      }" to "${
                        item && item.newhisEmail ? item.newhisEmail : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhisMobile != item.newhisMobile) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated hisMobile from "${
                        item && item.oldhisMobile ? item.oldhisMobile : "Nil"
                      }" to "${
                        item && item.newhisMobile ? item.newhisMobile : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldcity != item.newcity) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated City from "${
                        item && item.oldcity ? item.oldcity : "Nil"
                      }" to "${
                        item && item.newcity ? item.newcity : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldstate != item.newstate) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated State from "${
                        item && item.oldstate ? item.oldstate : "Nil"
                      }" to "${
                        item && item.newcity ? item.newcity : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldzip != item.newzip) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Zip from "${
                        item && item.oldzip ? item.oldzip : "Nil"
                      }" to "${
                        item && item.newzip ? item.newzip : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldnotes != item.newnotes) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Notes from "${
                        item && item.oldnotes ? item.oldnotes : "Nil"
                      }" to "${
                        item && item.newnotes ? item.newnotes : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldparish != item.newparish) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Parish from "${
                        item && item.oldparish ? item.oldparish : "Nil"
                      }" to "${
                        item && item.newparish ? item.newparish : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldaddress != item.newaddress) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${item.couple}): Updated Address from "${
                        item && item.oldaddress ? item.oldaddress : "Nil"
                      }" to "${
                        item && item.newaddress ? item.newaddress : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldallergies != item.newallergies) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's columns on "dietary restrictions or physical limitations" from "${
                        item && item.oldallergies ? item.oldallergies : "Nil"
                      }" to "${
                        item && item.newallergies ? item.newallergies : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldanniversary_date != item.newanniversary_date) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Anniversary Date from "${
                        item && item.oldanniversary_date
                          ? item.oldanniversary_date
                          : "Nil"
                      }" to "${
                        item && item.newanniversary_date
                          ? item.newanniversary_date
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldhear_us != item.newhear_us) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }):Updated Couple's column on "Under Age 35" from "${
                        item && item.oldhear_us ? item.oldhear_us : "Nil"
                      }" to "${
                        item && item.newhear_us ? item.newhear_us : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_name1 != item.newemergency_name1) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }):Updated Couple's column on "Emergency Name1" from "${
                        item && item.oldemergency_name1
                          ? item.oldemergency_name1
                          : "Nil"
                      }" to "${
                        item && item.newemergency_name1
                          ? item.newemergency_name1
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (
                    item.oldemergency_relationship1 !=
                    item.newemergency_relationship1
                  ) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Relationship1" from  "${
                        item && item.oldemergency_relationship1
                          ? item.oldemergency_relationship1
                          : "Nil"
                      }" to "${
                        item && item.newemergency_relationship1
                          ? item.newemergency_relationship1
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_phone1 != item.newemergency_phone1) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Phone1"  from "${
                        item && item.oldemergency_phone1
                          ? item.oldemergency_phone1
                          : "Nil"
                      }" to "${
                        item && item.newemergency_phone1
                          ? item.newemergency_phone1
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_name2 != item.newemergency_name2) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Name2" from "${
                        item && item.oldemergency_name2
                          ? item.oldemergency_name2
                          : "Nil"
                      }" to "${
                        item && item.newemergency_name2
                          ? item.newemergency_name2
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (
                    item.oldemergency_relationship2 !=
                    item.newemergency_relationship2
                  ) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Relationship2"  from "${
                        item && item.oldemergency_relationship2
                          ? item.oldemergency_relationship2
                          : "Nil"
                      }" to "${
                        item && item.newemergency_relationship2
                          ? item.newemergency_relationship2
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldemergency_phone2 != item.newemergency_phone2) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Emergency Phone2" from "${
                        item && item.oldemergency_phone2
                          ? item.oldemergency_phone2
                          : "Nil"
                      }" to "${
                        item && item.newemergency_phone2
                          ? item.newemergency_phone2
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldunder_age_35 != item.newunder_age_35) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Hear about us" from "${
                        item && item.oldunder_age_35
                          ? item.oldunder_age_35
                          : "Nil"
                      }" to "${
                        item && item.newunder_age_35
                          ? item.newunder_age_35
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (
                    item.oldreferral_from_a_friend_or_family_member !=
                    item.newreferral_from_a_friend_or_family_member
                  ) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `(${
                        item.couple
                      }): Updated Couple's column on "Referral from a friend/Family member" from "${
                        item && item.oldreferral_from_a_friend_or_family_member
                          ? item.oldreferral_from_a_friend_or_family_member
                          : "Nil"
                      }" to "${
                        item && item.newreferral_from_a_friend_or_family_member
                          ? item.newreferral_from_a_friend_or_family_member
                          : "Nil"
                      }" through Excel file import (Retreat id : ${retreatId})`,
                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                })
              );
            }

            if (response.updateRoaster && response.updateRoaster.length > 0) {
              await Promise.all(
                response.updateRoaster.map(async (item) => {
                  if (item.oldRole !== item.newRole) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `Updated Role of Couple in Retreat Roast (Retreat : ${retreatId}), ${
                        item.couple
                      } From "${
                        item && item.oldRole ? item.oldRole : "Nil"
                      }" to "${
                        item && item.newRole ? item.newRole : "Nil"
                      }" through Excel file import`,

                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldTalk !== item.newTalk) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `Updated Role of Couple in Retreat Roast (Retreat : ${retreatId}), ${
                        item.couple
                      } From "${
                        item && item.oldTalk ? item.oldTalk : "Nil"
                      }" to "${
                        item && item.newTalk ? item.newTalk : "Nil"
                      }" through Excel file import`,

                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldService !== item.newService) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `Updated Service of Couple in Retreat Roast (Retreat : ${retreatId}), ${
                        item.couple
                      } From "${
                        item && item.oldService ? item.oldService : "Nil"
                      }" to "${
                        item && item.newService ? item.newService : "Nil"
                      }" through Excel file import`,

                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  if (item.oldAttendeeType !== item.newAttendeeType) {
                    let report = {
                      byWhom:
                        `${
                          userData && userData.userName
                            ? userData.userName
                            : null
                        } ` +
                        ` (${
                          userData && userData.userRole
                            ? userData.userRole
                            : null
                        })`,
                      pagelink: window.location.href,

                      description: `Updated AttendeeType of Couple in Retreat Roast (Retreat : ${retreatId}), ${
                        item.couple
                      } From "${
                        item && item.oldAttendeeType
                          ? item.oldAttendeeType
                          : "Nil"
                      }" to "${
                        item && item.newAttendeeType
                          ? item.newAttendeeType
                          : "Nil"
                      }" through Excel file import`,

                      action: "Click",
                    };
                    await adminApi
                      .addLogReport(report)
                      .then((response) => {
                        if (response.success) {
                          console.log("Created new log report.");
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                })
              );
            }

            document.getElementById("fileUpload").value = "";
            toast.success("File data imported successfully.");
            getRetreatRoastData();
            getRetreatRoastData1();
            leadCoupleData1();
            setLoadData(false);
          } else {
            document.getElementById("fileUpload").value = "";
            toast.error(response.msg);
            getRetreatRoastData();
            getRetreatRoastData1();
            leadCoupleData1();
            setLoadData(false);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
          document.getElementById("fileUpload").value = "";
          console.error("There is an error!", error);
        });
    }
  };

  const handleExport = async () => {
    let d = {};
    d.pageNo = 1;
    d.size = 20;
    d.export = true;
    d.retreatId = retreatId;
    await retreatRoastApi
      .getRetreatRoastData(d)
      .then(async (res) => {
        if (res.success) {
          let exportData = [];
          res.data.forEach((element) => {
            exportData.push({
              primaryKey: element.primaryKey,
              attendeeType: element.attendeeType,
              hisLastName: element.hisLastName,
              hisFirstName: element.hisFirstName,
              herLastName: element.herLastName,
              herFirstName: element.herFirstName,
              hisEmail: element.hisEmail,
              herEmail: element.herEmail,
              hisMobile: element.hisMobile,
              herMobile: element.herMobile,
              // parishName: element.parish,
              roleTitle: element.role,
              serviceTitle: element.service,
              talkTitle: element.talk,
              address: element.address,
              city: element.city,
              state: element.state,
              zip: element.zip,
              parish: element.parish,
              Anniversary_date:
                element.anniversary_date !== null
                  ? Helpers.convertUtcToTimezone(
                      element.anniversary_date,
                      "MM-DD-YYYY"
                    )
                  : "",
              Dietary_restrictions_or_physical_limitations: element.allergies,
              Under_age_35: element.under_age_35,
              Emergency_name1: element.emergency_name1,
              Emergency_relationship1: element.emergency_relationship1,
              Emergency_phone1: element.emergency_phone1,
              Emergency_name2: element.emergency_name2,
              Emergency_relationship2: element.emergency_relationship2,
              Emergency_phone2: element.emergency_phone2,
              // How_did_you_hear_about_us: element.hear_us,
              Referral_from_a_friend_or_family_member:
                element.referral_from_a_friend_or_family_member,
              notes: element.notes,
            });
          });
          const headings = [
            [
              "Couple Id",
              "Type",
              "His Last Name",
              "His First Name",
              "Her Last Name",
              "Her First Name",
              "His Email",
              "Her Email",
              "His Mobile",
              "Her Mobile",
              "Service Role",
              "Service Title",
              "Talk ",
              "Address",
              "City",
              "State",
              "Zip",
              "Parish",
              "Anniversary Date",
              "Dietary Restrictions Or Physical Limitations",
              "Under Age 35",
              "Emergency Name1",
              "Emergency Relationship1",
              "Emergency Phone1",
              "Emergency Name2",
              "Emergency Relationship2",
              "Emergency Phone2",
              // "How did you hear about us?",
              "Referral from a friend/family member",
              "Notes",
            ],
          ];
          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);
          utils.sheet_add_aoa(ws, headings);
          utils.sheet_add_json(ws, exportData, {
            origin: "A2",
            skipHeader: true,
          });
          utils.book_append_sheet(wb, ws, "Data");
          console.log("retreatDetail-->", retreatDetail);
          writeFile(
            wb,
            `${
              retreatDetail && retreatDetail.title
                ? `${
                    retreatDetail &&
                    retreatDetail.title &&
                    retreatDetail.title.charAt(0).toUpperCase() +
                      retreatDetail.title.slice(1)
                  }` +
                  " " +
                  `${
                    retreatDetail.dateFrom == retreatDetail.dateTo
                      ? moment(retreatDetail && retreatDetail.dateFrom).format(
                          "MMM-DD-YYYY"
                        )
                      : moment(retreatDetail && retreatDetail.dateFrom).format(
                          "MMM DD"
                        ) +
                        " - " +
                        moment(retreatDetail && retreatDetail.dateTo).format(
                          "MMM DD"
                        ) +
                        ", " +
                        moment(retreatDetail && retreatDetail.dateTo).format(
                          "YYYY"
                        )
                  }`
                : "Retreat Roaster"
            }.xlsx`
          );
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Exported Excel of Retreat Roaster ( RetreatId : ${retreatId} ).`,
            action: "Click",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // Retreat - Date - Language - Location
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const exportFlockNotes = async () => {
    let d = {};
    d.pageNo = 1;
    d.size = 20;
    d.export = true;
    d.retreatId = retreatId;
    await retreatRoastApi
      .getRetreatRoastData(d)
      .then(async (res) => {
        if (res.success) {
          let exportData = [];

          res.data.forEach((element) => {
            // Push his data
            exportData.push({
              firstName: element.hisFirstName,
              lastName: element.hisLastName,
              email: element.hisEmail,
              mobile: element.hisMobile,
            });
            // Push her data
            exportData.push({
              firstName: element.herFirstName,
              lastName: element.herLastName,
              email: element.herEmail,
              mobile: element.herMobile,
            });
          });

          const headings = [["First Name", "Last Name", "Email", "Mobile"]];

          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);
          utils.sheet_add_aoa(ws, headings);
          utils.sheet_add_json(ws, exportData, {
            origin: "A2",
            skipHeader: true,
          });
          utils.book_append_sheet(wb, ws, "Data");

          writeFile(
            wb,
            `${
              retreatDetail && retreatDetail.title
                ? `${
                    retreatDetail.title.charAt(0).toUpperCase() +
                    retreatDetail.title.slice(1)
                  }` +
                  " " +
                  `${
                    retreatDetail.dateFrom === retreatDetail.dateTo
                      ? moment(retreatDetail.dateFrom).format("MMM-DD-YYYY")
                      : moment(retreatDetail.dateFrom).format("MMM DD") +
                        " - " +
                        moment(retreatDetail.dateTo).format("MMM DD") +
                        ", " +
                        moment(retreatDetail.dateTo).format("YYYY")
                  }`
                : "Retreat Roaster"
            }.xlsx`
          );

          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              `(${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Exported Flock Note Excel of Retreat Roaster (RetreatId: ${retreatId}).`,
            action: "Click",
          };

          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const exportPdf = async () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "All Retreat Roster";
    const headers = [
      [
        "Couple Id",
        "Type",
        "His Last Name",
        "His First Name",
        "Her Last Name",
        "Her First Name",
        "His Email",
        "Her Email",
        "His Mobile",
        "Her Mobile",
        "Service Role",
        "Service Title",
        "Talk ",
        "Address",
        "City",
        "State",
        "Zip",
        "Parish",
        "Anniversary Date",
        "Dietary Restrictions Or Physical Limitations",
        "Under Age 35",
        "Emergency Name1",
        "Emergency Relationship1",
        "Emergency Phone1",
        "Emergency Name2",
        "Emergency Relationship2",
        "Emergency Phone2",
        "Referral from a friend/family member",
        "Notes",
      ],
    ];
    let d = {};
    d.pageNo = 1;
    d.size = 20;
    d.export = true;
    d.retreatId = retreatId;
    await retreatRoastApi
      .getRetreatRoastData(d)
      .then(async (res) => {
        if (res.success) {
          const data = res.data.map((element) => [
            element.primaryKey,
            element.attendeeType,
            element.hisLastName,
            element.hisFirstName,
            element.herLastName,
            element.herFirstName,
            element.hisEmail,
            element.herEmail,
            element.hisMobile,
            element.herMobile,
            element.role,
            element.service,
            element.talk,
            element.address,
            element.city,
            element.state,
            element.zip,
            element.parish,
            element.anniversary_date !== null
              ? Helpers.convertUtcToTimezone(
                  element.anniversary_date,
                  "MM-DD-YYYY"
                )
              : "",
            element.allergies,
            element.under_age_35,
            element.emergency_name1,
            element.emergency_relationship1,
            element.emergency_phone1,
            element.emergency_name2,
            element.emergency_relationship2,
            element.emergency_phone2,
            element.referral_from_a_friend_or_family_member,
            element.notes,
          ]);

          let content = {
            head: headers,
            body: data,
            startY: 70,
            theme: "grid",
            tableLineColor: [189, 195, 199],
            tableLineWidth: 0.75,
          };
          doc.text(title, marginLeft, 40);
          doc.autoTable(content);
          doc.save(
            `${
              retreatDetail && retreatDetail.title
                ? `${
                    retreatDetail &&
                    retreatDetail.title &&
                    retreatDetail.title.charAt(0).toUpperCase() +
                      retreatDetail.title.slice(1)
                  }` +
                  `${
                    retreatDetail.dateFrom == retreatDetail.dateTo
                      ? moment(retreatDetail && retreatDetail.dateFrom).format(
                          "MMM-DD-YYYY"
                        )
                      : moment(retreatDetail && retreatDetail.dateFrom).format(
                          "MMM DD"
                        ) +
                        " - " +
                        moment(retreatDetail && retreatDetail.dateTo).format(
                          "MMM DD"
                        ) +
                        ", " +
                        moment(retreatDetail && retreatDetail.dateTo).format(
                          "YYYY"
                        )
                  }`
                : "Retreat Roaster"
            }.pdf`
          );
          let report = {
            byWhom:
              `${userData && userData.userName ? userData.userName : null} ` +
              ` (${userData && userData.userRole ? userData.userRole : null})`,
            pagelink: window.location.href,
            description: `Exported PDF file of Retreat Roater. ( RetreatId : ${retreatId} )`,
            action: "Click",
          };
          await adminApi
            .addLogReport(report)
            .then((response) => {
              if (response.success) {
                console.log("Created new log report.");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">{props}</Tooltip>
  );

  const SearchData = (event) => {
    const key = event.target.value;
    if (key !== "") {
      setSearchKey(event.target.value);
      getRetreatRoastData(1, 20, event.target.value);
    } else {
      setSearchKey("");
      getRetreatRoastData(1, 20, "");
    }
  };

  const downloadSampleExcel = async () => {
    const headings = [
      [
        "Couple Id",
        "Type",
        "His Last Name",
        "His First Name",
        "Her Last Name",
        "Her First Name",
        "His Email",
        "Her Email",
        "His Mobile",
        "Her Mobile",
        "Service Role",
        "Service Title",
        "Talk ",
        "Address",
        "City",
        "State",
        "Zip",
        "Parish",
        "Anniversary Date",
        "Dietary Restrictions Or Physical Limitations",
        "Under Age 35",
        "Emergency Name1",
        "Emergency Relationship1",
        "Emergency Phone1",
        "Emergency Name2",
        "Emergency Relationship2",
        "Emergency Phone2",
        "How did you hear about us?",
        "Referral from a friend/family member",
        "Notes",
      ],
    ];
    const data = [
      {
        primaryKey: "001",
        type: "Team",
        hisLastName: "Paul",
        hisFirstName: "Sam",
        herLastName: "Paul",
        herFirstName: " Charisse",
        hisEmail: "sampaul@gmail.com",
        herEmail: "charisseAdams@gmail.com",
        hisMobile: "210-323-0384",
        herMobile: "210-459-4955",
        role: "Co-Head Couple",
        service: "Kitchen",
        talk: "Children and In-Laws",
        address: "101 Balistreri Overpass, Cummerataland, KY 42976",
        city: "Los Angeles",
        state: "California",
        zip_code: "123456",
        parish: "Holy Spirit",
        ainniversary_date: "04-02-2000",
        dietary_restrictions_or_physical_limitations:
          "Dietary restrictions or physical limitations",
        under_age_35: "NO",
        emergency_name_1: "cris",
        emergency_relationship_1: "friend",
        emergency_phone_1: "6445-7645-6786",
        emergency_name_2: "Eric",
        emergency_relationship_2: "Broother",
        emergency_phone_2: "1425-7645-6786",
        hear_about_us: ["School Marquee", "Facebook"],
        Referral_from_a_friend_or_Family_member: "sister",
        notes: "Your notes here.",
      },
      {
        primaryKey: "002",
        type: "Retreatant",
        hisLastName: "Sparow",
        hisFirstName: "Tom",
        herLastName: "Sparow",
        herFirstName: " Zean",
        hisEmail: "tom@gmail.com",
        herEmail: "zean@gmail.com",
        hisMobile: "540-723-0384",
        herMobile: "110-454-4957",
        role: "Lead",
        service: "Music",
        talk: "Marital Bliss",
        address: "Tata Nagar, Opp:ashok Tea House, Deonar (east)",
        city: "Nanwanling	China",
        state: "Tennessee",
        zip_code: "5654756",
        parish: "Holy Spirit",
        ainniversary_date: "07-11-2010",
        dietary_restrictions_or_physical_limitations:
          "Autoimmunity-Immune Deficiency.",
        under_age_35: "NO",
        emergency_name_1: "norman",
        emergency_relationship_1: "father",
        emergency_phone_1: "5656-5657-6786",
        emergency_name_2: "bilosh",
        emergency_relationship_2: "Mother",
        emergency_phone_2: "1425-7645-6786",
        hear_about_us: ["Facebook"],
        Referral_from_a_friend_or_Family_member: "Unle",
        notes: "Your second notes here111.",
      },

      {
        primaryKey: "003",
        type: "Retreatant",
        hisLastName: "Alanis",
        hisFirstName: "Arnulfo",
        herLastName: "Alanis",
        herFirstName: "Millie",
        hisEmail: "arnulfo@gmail.com",
        herEmail: "millie@gmail.com",
        hisMobile: "560-723-4384",
        herMobile: "870-154-4957",
        role: "",
        service: "",
        talk: "",
        address: "Suite 687 3380 Liane Port, Harberberg, OH 95790",
        city: "Nanwanling	China",
        state: "Tennessee",
        zip_code: "5654756",
        parish: "Sts. Peter and Paul",
        ainniversary_date: "07-11-2015",
        dietary_restrictions_or_physical_limitations: "Sinusitis",
        under_age_35: "YES",
        emergency_name_1: "vintrel",
        emergency_relationship_1: "Friend",
        emergency_phone_1: "566-5657-6786",
        emergency_name_2: "orbio",
        emergency_relationship_2: "",
        emergency_phone_2: "3232-7645-6786",
        hear_about_us: ["Facebook", "Church Bulletin"],
        Referral_from_a_friend_or_Family_member: "Unle",
        notes: "Your second notes here111.",
      },

      {
        primaryKey: "004",
        type: "Retreatant",
        hisLastName: "Jamson",
        hisFirstName: "Beon",
        herLastName: "Jamson",
        herFirstName: "Khalisi",
        hisEmail: "beos@gmail.com",
        herEmail: "khalisi@gmail.com",
        hisMobile: "560-723-4384",
        herMobile: "980-154-2957",
        role: "Team Member",
        service: "Tables",
        talk: "Communication",
        address: "Tata Nagar, Opp:ashok Tea House, Deonar (east)",
        city: "Nanwanling	China",
        state: "Tennessee",
        zip_code: "5654756",
        parish: "Holy Spirit",
        ainniversary_date: "07-11-2013",
        dietary_restrictions_or_physical_limitations: "ledi 11 nirmal",
        under_age_35: "NO",
        emergency_name_1: "wisel",
        emergency_relationship_1: "father",
        emergency_phone_1: "5656-5657-6786",
        emergency_name_2: "dekip",
        emergency_relationship_2: "Mother",
        emergency_phone_2: "7878-7645-6786",
        hear_about_us: ["Church Bulletin"],
        Referral_from_a_friend_or_Family_member: "Aunty",
        notes: "Your third notes here111.",
      },
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, "Sample RetreatRoaster File.xlsx");
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description: `Downloaded Sample Excel File of Retreat Roaster.`,
      action: "Click",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const popover = (row) => {
    return (
      <Popover id="popover-ImgPk" bsPrefix="popover">
        <Popover.Body>
          <div style={{ textAlign: "center", padding: "10px 0px" }}>
            {row.imageUrl ? (
              <span>
                <img src={row.imageUrl} className="column-rowImagePopover" />
              </span>
            ) : (
              <img src={Profile} className="column-rowImagePopover" />
            )}
            <h6 className="couplename">
              {" "}
              {row &&
                row.hisLastName +
                  ", " +
                  row.hisFirstName +
                  " and " +
                  row.herFirstName}
            </h6>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const editRoast = async (row) => {
    setIsNewCouple(false);
    setStates(row);
    // setOldValues(row);
    setShowModel(true);
    let report = {
      byWhom:
        `${userData && userData.userName ? userData.userName : null} ` +
        ` (${userData && userData.userRole ? userData.userRole : null})`,
      pagelink: window.location.href,
      description:
        `Checked Detail of Retreat Roast ` +
        `(Retreat : ${
          retreatDetail &&
          retreatDetail.title &&
          retreatDetail.title.charAt(0).toUpperCase() +
            retreatDetail.title.slice(1)
        }), ` +
        `Couple : (${row.primaryKey ? `${row.primaryKey} - ` : ""}` +
        `${row && row.hisLastName ? `${row.hisLastName}` : ""}` +
        `${row && row.hisFirstName ? `, ${row.hisFirstName}` : ""}` +
        `${row && row.herFirstName ? ` and  ${row.herFirstName}` : ""})`,
      action: "View",
    };
    await adminApi
      .addLogReport(report)
      .then((response) => {
        if (response.success) {
          console.log("Created new log report.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    ...(userData && userData.userRole === "ADMIN"
      ? [
          {
            id: "action",
            name: "Action",
            button: true,
            cell: (row) => (
              <>
                <button
                  className="font-size"
                  onClick={() => {
                    editRoast(row);
                  }}
                >
                  <i
                    className="mdi mdi-pencil-box text-lg text-primary font-size"
                    title="Edit"
                  ></i>
                </button>
                <button className="font-size" onClick={() => handleShow(row)}>
                  <i
                    className="mdi mdi-delete-forever text-md text-danger mx-2 font-size"
                    title="Delete"
                  ></i>
                </button>
              </>
            ),
            reorder: true,
            width: "100px",
          },
        ]
      : []),
    {
      id: "primaryKey",
      name: "Couple Id",
      selector: (row) => row.primaryKey,
      cell: (row) => (
        <>
          {(row.role == "Spiritual Mentor" && (
            <span className="line2"></span>
          )) ||
            (row.role == "Lead" && <span className="line1"></span>) ||
            (row.attendeeType == "Team" && <span className="line4"></span>) ||
            (row.attendeeType == "Retreatant" && (
              <span className="line3"></span>
            )) || <span className="line5"></span>}
          <OverlayTrigger
            placement="right"
            // delay={{ show: 250, hide: 400 }}
            overlay={popover(row)}
          >
            {/* <span>{row.primaryKey}</span> */}
            {row.imageUrl ? (
              <span
                onClick={() => {
                  editCouple(row.coupleId);
                  setShowREgModal(true);
                  getroastersOfCouple(row.coupleId);
                }}
              >
                <img src={row.imageUrl} className="column-rowImage" />
                {row.primaryKey && row.primaryKey}
              </span>
            ) : (
              <span
                onClick={() => {
                  editCouple(row.coupleId);
                  setShowREgModal(true);
                  getroastersOfCouple(row.coupleId);
                }}
              >
                <img src={Profile} className="column-rowImage" />
                {row.primaryKey && row.primaryKey}
              </span>
            )}
          </OverlayTrigger>
        </>
      ),
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      id: "Retreatant",
      name: "Type",
      selector: (row) =>
        row && row.attendeeType !== null ? row.attendeeType : "",
      reorder: true,
      sortable: true,
      width: "120px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "his Last Name",
      name: "His Last Name",
      selector: (row) => row.hisLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisLastName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisLastName}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      id: "his First Name",
      name: "His First Name",
      selector: (row) => row.hisFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.hisFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.hisFirstName}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      id: "Her Last Name",
      name: "Her Last Name",
      selector: (row) => row.herLastName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herLastName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herLastName}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      id: "her First Name",
      name: "Her First Name	",
      selector: (row) => row.herFirstName,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.herFirstName)}
        >
          <span
            onClick={() => {
              editCouple(row.coupleId);
              setShowREgModal(true);
              getroastersOfCouple(row.coupleId);
            }}
          >
            {row.herFirstName}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      id: "His Email",
      name: "His Email",
      selector: (row) => row.hisEmai,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row && row.hisEmail)}
        >
          <a href={`mailto:${row.hisEmail}`}>
            {" "}
            <span>
              {row.hisEmail &&
                row.hisEmail.slice(0, 12) +
                  (row.hisEmail.length > 12 ? "..." : "")}
            </span>{" "}
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "160px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Her Email",
      name: "Her Email",
      selector: (row) => row.herEmail,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row && row.herEmail)}
        >
          <a href={`mailto:${row.herEmail}`}>
            {" "}
            <span>
              {row.herEmail &&
                row.herEmail.slice(0, 12) +
                  (row.herEmail.length > 12 ? "..." : "")}
            </span>
          </a>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "160px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "His Mobile",
      name: "His Mobile",
      selector: (row) => row.hisMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row && row.hisMobile)}
        >
          <span>{row.hisMobile}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Her Mobile",
      name: "Her Mobile",
      selector: (row) => row.herMobile,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row && row.herMobile)}
        >
          <span>{row.herMobile}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "150px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.address)}
        >
          <span>
            {row.address &&
              row.address.slice(0, 10) + (row.address.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Parish",
      name: "Parish",
      selector: (row) => (row && row.parish !== null ? row.parish : ""),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row && row.parish)}
        >
          <span>{row && row.parish}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "120px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Role",
      name: "Service Role",
      selector: (row) => (row && row.role !== null ? row.role : ""),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span>{row.role}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Service",
      name: "Service Title",
      selector: (row) => (row && row.service !== null ? row.service : ""),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span>{row.service}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "130px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Talk",
      name: "Talk",
      selector: (row) => (row && row.talk !== null ? row.talk : ""),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span>{row.talk}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "170px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.city)}
        >
          <span>
            {row.city &&
              row.city.slice(0, 10) + (row.city.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.state)}
        >
          <span>
            {row.state &&
              row.state.slice(0, 10) + (row.state.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "zip",
      name: "Zip",
      selector: (row) => row.zip,
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Anniversary Date",
      name: "Anniversary Date",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY")
          )}
        >
          <span>
            {row.anniversary_date
              ? Helpers.convertUtcToTimezone(row.anniversary_date, "M/D/YYYY")
              : "--"}
          </span>
        </OverlayTrigger>
      ),
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    /*{
      id: "Dietary restrictions or physical limitations",
      name: "Dietary restrictions or physical limitations",
      selector: (row) => row.allergies,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.allergies)}
        >
          <span>
            {row.allergies &&
              row.allergies.slice(0, 10) +
                (row.allergies.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name1",
      name: "Emergency Name1",
      selector: (row) => row.emergency_name1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name1)}
        >
          <span>
            {row.emergency_name1 &&
              row.emergency_name1.slice(0, 12) +
                (row.emergency_name1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship1",
      name: "Emergency Relationship1",
      selector: (row) => row.emergency_relationship1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship1)}
        >
          <span>
            {row.emergency_relationship1 &&
              row.emergency_relationship1.slice(0, 12) +
                (row.emergency_relationship1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone1",
      name: "Emergency Phone1",
      selector: (row) => row.emergency_phone1,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone1)}
        >
          <span>
            {row.emergency_phone1 &&
              row.emergency_phone1.slice(0, 12) +
                (row.emergency_phone1.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Name2",
      name: "Emergency Name2",
      selector: (row) => row.emergency_name2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_name2)}
        >
          <span>
            {row.emergency_name2 &&
              row.emergency_name2.slice(0, 12) +
                (row.emergency_name2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Relationship2",
      name: "Emergency Relationship2",
      selector: (row) => row.emergency_relationship2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_relationship2)}
        >
          <span>
            {row.emergency_relationship2 &&
              row.emergency_relationship2.slice(0, 12) +
                (row.emergency_relationship2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Emergency Phone2",
      name: "Emergency Phone2",
      selector: (row) => row.emergency_phone2,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.emergency_phone2)}
        >
          <span>
            {row.emergency_phone2 &&
              row.emergency_phone2.slice(0, 12) +
                (row.emergency_phone2.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Hear about us",
      name: "Hear about us",
      selector: (row) => row.hear_us,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
          )}
        >
          <span>
            {row.hear_us &&
              row.hear_us
                .slice(2, -2)
                .split('","')
                .map((source) => source.replace(/\\"/g, ""))
                .join(", ")
                .slice(0, 12) + (row.hear_us.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Referral from a friend/Family member",
      name: "Referral from a friend/Family member",
      selector: (row) => row.referral_from_a_friend_or_family_member,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.referral_from_a_friend_or_family_member)}
        >
          <span>
            {row.referral_from_a_friend_or_family_member &&
              row.referral_from_a_friend_or_family_member.slice(0, 12) +
                (row.referral_from_a_friend_or_family_member.length > 12
                  ? "..."
                  : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "Under Age 35",
      name: "Under Age 35",
      selector: (row) => row.under_age_35,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.under_age_35)}
        >
          <span>
            {row.under_age_35 &&
              row.under_age_35.slice(0, 12) +
                (row.under_age_35.length > 12 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },
    {
      id: "notes",
      name: "Notes",
      selector: (row) => row.notes,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.notes)}
        >
          <span>
            {row.notes &&
              row.notes.slice(0, 10) + (row.notes.length > 10 ? "..." : "")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      style: {
        cursor: "text",
      },
    },*/
    {
      id: "created",
      name: "Created",
      selector: (row) =>
        Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY"),
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY hh:mm:a")
          )}
        >
          <span>
            {Helpers.convertUtcToTimezone(row.createdAt, "MM-DD-YYYY")}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "112px",
      style: {
        cursor: "text",
      },
    },
  ];

  const columns1 = [
    {
      id: "title",
      name: "Retreats",
      selector: (row) => (
        <div className="titleWithDate">
          <a
            href={`/retreatRoaster?retreatId=${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="retreat_title"
          >
            <div>
              {`${
                row.title !== null && row.title != ""
                  ? `${row.title.charAt(0).toUpperCase() + row.title.slice(1)}`
                  : ""
              }`}

              {/* {
                `
              ${row.parish !== null && row.parish != '' ? `${row.parish.charAt(0).toUpperCase() + row.parish.slice(1) + ", "}` : ''}
              ${row.language !== null && row.language != '' ? `${row.language.charAt(0).toUpperCase() + row.language.slice(1) + ", "}` : ''}
              ${row.location !== null && row.location != '' ? `${row.location.charAt(0).toUpperCase() + row.location.slice(1)} ` : ''} `
              } */}
            </div>
          </a>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              row.dateFrom == row.dateTo
                ? moment(row && row.dateFrom).format("MMM-DD-YYYY")
                : moment(row && row.dateFrom).format("MMM DD") +
                    " - " +
                    moment(row && row.dateTo).format("MMM DD") +
                    ", " +
                    moment(row && row.dateTo).format("YYYY")
            )}
          >
            <span>
              {row.dateFrom == row.dateTo
                ? moment(row && row.dateFrom).format("MMM-DD-YYYY")
                : moment(row && row.dateFrom).format("MMM DD") +
                  " - " +
                  moment(row && row.dateTo).format("MMM DD") +
                  ", " +
                  moment(row && row.dateTo).format("YYYY")}
            </span>
          </OverlayTrigger>
        </div>
      ),
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: "AttendeeType",
      name: "Type",
      selector: (row) => row && row.attendeeType,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.attendeeType)}
        >
          {row && row.attendeeType == "Team" ? (
            <span className="badge badge-danger">{row.attendeeType}</span>
          ) : (
            <span className="badge badge-success">{row.attendeeType}</span>
          )}
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Role",
      name: "Role",
      selector: (row) => row && row.role,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.role)}
        >
          <span>{row.role ? row.role : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Service Title",
      name: "Service Title",
      selector: (row) => row && row.service,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.service)}
        >
          <span>{row.service ? row.service : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
      style: {
        cursor: "text",
      },
    },
    {
      id: "Talk",
      name: "Talk",
      selector: (row) => row && row.talk,
      cell: (row) => (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(row.talk)}
        >
          <span>{row.talk ? row.talk : ""}</span>
        </OverlayTrigger>
      ),
      sortable: true,
      reorder: true,
      width: "230px",
      style: {
        cursor: "text",
      },
    },
  ];

  return (
    <>
      {userData && userData.userRole === "ADMIN" ? (
        <div className="container-fluid setclear">
          <h6 className="Set_clear">
            <u>
              <a onClick={clearFilter}>Clear Filter</a>
            </u>
          </h6>
          <div className="circles">
            <div className="circles2">
              <div className="d-flex">
                <span className="dot1"></span>
                <h6 className="default">
                  <a onClick={leadCoupleData}>Lead Couple </a>
                </h6>
              </div>
              <div className="d-flex">
                <span className="dot2"></span>
                <h6 className="default">
                  <a onClick={spritualData}>Spiritual Mentor</a>
                </h6>
              </div>
            </div>
            <div className="circles2">
              <div className="d-flex ">
                <span className="dot3"></span>
                <h6 className="default">
                  <a onClick={RetreatData}>Retreatant</a>
                </h6>
              </div>
              <div className="d-flex">
                <span className="dot4"></span>
                <h6 className="default">
                  <a onClick={teamData}>Team</a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-lg-12 d-flex flex-column">
          <div className="card custom-card">
            <div className="set_padding card-body ">
              <div className="row head_items Couple_heading">
                <Col lg={6} md={4} sm={12} xs={12}>
                  <h3 className="text-title">
                    {retreatRoastLeadData[0] &&
                      retreatRoastLeadData[0].hisLastName +
                        ", " +
                        retreatRoastLeadData[0].hisFirstName +
                        " and " +
                        retreatRoastLeadData[0].herFirstName}
                  </h3>
                  {/* {
                    retreatRoastLeadData && retreatRoastLeadData.map((item, index) => {
                      if (index > 0) {
                        return (
                          <h6 className="setspan">
                            {item.hisLastName + ", " + item.hisFirstName + " and " + item.herFirstName}
                          </h6>
                        )
                      }
                    })
                  } */}
                  <h6>
                    {retreatDetail && retreatDetail.title
                      ? `${
                          retreatDetail &&
                          retreatDetail.title &&
                          retreatDetail.title.charAt(0).toUpperCase() +
                            retreatDetail.title.slice(1)
                        }`
                      : null}
                  </h6>
                  <h6>
                    {retreatDetail.dateFrom == retreatDetail.dateTo
                      ? moment(retreatDetail && retreatDetail.dateFrom)
                          .tz("America/New_York")
                          .format("MMM-DD-YYYY")
                      : moment(retreatDetail && retreatDetail.dateFrom)
                          .tz("America/New_York")
                          .format("MMM DD") +
                        " - " +
                        moment(retreatDetail && retreatDetail.dateTo)
                          .tz("America/New_York")
                          .format("MMM DD") +
                        ", " +
                        moment(retreatDetail && retreatDetail.dateTo)
                          .tz("America/New_York")
                          .format("YYYY")}
                  </h6>
                </Col>
                <Col
                  lg={6}
                  md={8}
                  sm={12}
                  xs={12}
                  className="justify-content-end mb-2 d-sm-flex head_button"
                >
                  <label className="me-3">
                    {/* <input type="search" onChange={SearchData} className="form-control" placeholder="Search by Keyword" aria-controls="order-listing" /> */}
                    <div className="form-group">
                      <div className="input-group setSearch">
                        <input
                          onChange={SearchData}
                          type="text"
                          className="form-control searchData"
                          placeholder="Search..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-primary"
                            type="button"
                          >
                            <i className="icon-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </label>
                  {userData && userData.userRole === "ADMIN" ? (
                    <div className="btn-wrapper import_buttons">
                      <a
                        onClick={downloadSampleExcel}
                        className=" btn me-2 set_text"
                      >
                        (Download Sample Excel)
                      </a>
                      <div className="import_drop">
                        <a
                          onClick={clickOnInputFile}
                          className="btn btn-outline-primary btn-sm me-1 mt-2 impbtn import_button"
                        >
                          <i className="icon-upload"></i>
                          Import
                        </a>
                        <input
                          className="btn btn-secondary"
                          type="file"
                          name="File Upload"
                          id="fileUpload"
                          hidden
                          onChange={fileChangeListener}
                          accept=".xlsx"
                        />

                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn btn-outline-secondary btn-sm mt-2"
                            id="dropdown-basic"
                          >
                            <i className="icon-download"></i>
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <a onClick={handleExport}>
                              {" "}
                              <Dropdown.Item>Excel</Dropdown.Item>
                            </a>
                            <a onClick={exportPdf}>
                              {" "}
                              <Dropdown.Item>PDF</Dropdown.Item>
                            </a>
                            <a onClick={exportFlockNotes}>
                              {" "}
                              <Dropdown.Item>Flock Note Excel</Dropdown.Item>
                            </a>
                          </Dropdown.Menu>
                        </Dropdown>

                        <button
                          className="text-big add-user newroaster"
                          type="button"
                          onClick={() => {
                            setRoasterdData("");
                            addRoaster();
                          }}
                        >
                          <i
                            className="mdi mdi mdi-account-plus text-white"
                            title="Add New User"
                          ></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn btn-outline-secondary btn-sm"
                        id="dropdown-basic"
                      >
                        <i className="icon-download"></i>
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <a onClick={handleExport}>
                          {" "}
                          <Dropdown.Item>Excel</Dropdown.Item>
                        </a>
                        <a onClick={exportPdf}>
                          {" "}
                          <Dropdown.Item>PDF</Dropdown.Item>
                        </a>
                        <a onClick={exportFlockNotes}>
                          {" "}
                          <Dropdown.Item>Flock Note Excel</Dropdown.Item>
                        </a>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Col>
              </div>
              {userData && userData.userRole === "ADMIN" ? (
                <Container fluid className="count_data mt-2">
                  <Row>
                    {/* <Col lg={3} sm={4} xs={6}>
                    <p className="default1"><a onClick={allParticipants}>Participants: {totalRoast}</a></p>
                  </Col> */}
                    <Col lg={3} sm={4} xs={6}>
                      <p className="default1">
                        {" "}
                        <a onClick={teamData}> Team: {team_count}</a>
                      </p>
                    </Col>
                    <Col lg={3} sm={4} xs={6}>
                      <p className=" default1">
                        {" "}
                        <a onClick={RetreatData}>Retreatant:{ret_count}</a>{" "}
                      </p>
                    </Col>
                    <Col lg={3} sm={4} xs={6}>
                      <p className=" default1">
                        <a onClick={serviceData}>Services: {service_count}</a>{" "}
                      </p>
                    </Col>
                    <Col lg={3} sm={4} xs={6}>
                      <p className="default1">
                        <a onClick={talkData}>Talks:{talk_count}</a>{" "}
                      </p>
                    </Col>
                    <Col lg={3} sm={4} xs={6}>
                      <p className="default1">
                        <a onClick={leadCoupleData}>Lead:{lead_count}</a>{" "}
                      </p>
                    </Col>
                    <Col lg={3} sm={4} xs={6}>
                      <p className="default1">
                        <a onClick={spritualData}>
                          Spiritual Mentor:{spritual_count}
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Container>
              ) : null}
            </div>
            <CustomDataTable
              loading={LoadData}
              data={retreatRoastData}
              countPerPage={20}
              isServerSide={true}
              pagination={true}
              sortServer={true}
              total={total}
              columns={columns}
              dataFetchApi={getRetreatRoastData}
              pointerOnHover={true}
              highlightOnHover={true}
              // fixedHeader={true}
            />
          </div>
        </div>
        {showRegModal ? (
          <UpdateCouplePopUp
            showRegModal={showRegModal}
            setShowREgModal={setShowREgModal}
            setEdiUserIdClicked={setEdiUserIdClicked}
            onHide={() => {
              CloseCompose();
              clearfield();
              setEdiUserIdClicked("");
            }}
            closeButton={() => {
              checkPKCouple();
              setEdiUserIdClicked("");
              CloseCompose();
              getRetreatRoastData();
            }}
            userData={userData}
            isEditAdmin={isEditAdmin}
            parishList={parishList}
            ediUserIdClicked={ediUserIdClicked}
            CloseCompose={CloseCompose}
            roasterCount={roasterCount}
            roastersOfCoupleList={roastersOfCoupleList}
            getAllCouples={getRetreatRoastData}
            data={data}
            setData={setData}
            oldValues={oldValues}
            setOldValues={setOldValues}
            isTempUpload={isTempUpload}
            // isTempUpload2={isTempUpload2}
            setIsTempUpload={setIsTempUpload}
            // setIsTempUpload2={setIsTempUpload2}
          />
        ) : null}
        {showModel ? renderEditRetreatModel() : null}
        <DeleteModel
          show={show}
          handleClose={handleClose}
          DeleteData={DeleteData}
        ></DeleteModel>
      </div>
    </>
  );
};

export default RetreatRoaster;
